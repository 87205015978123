import React from 'react';
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { ToastMessage } from "./FormElements";

export function handleFormSaveError(context, err) {
    if (err && err.validationMessages && (Object.keys(err.validationMessages ?? {}) ?? []).length) {
        context.setState({ errors: err.validationMessages, saving: false });
    }
    else if (err && err.handledError) {
        toast.error(
            <ToastMessage
                icon={faExclamationTriangle}
                header={'Unable to Save'}
                message={err.handledError.message}
            />
        );
    }
    else {
        toast.error(
            <ToastMessage
                icon={faExclamationTriangle}
                header={'Unable to Save'}
                message={'There was a server error when attempting to save.  Please try your submission again, or contact support for assistance.'}
            />
        );
    }
    return false;
}

export class ValidationError extends Error {
    constructor(validationMessages, ...params) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...params)
        
        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ValidationError)
        }

        this.name = 'ValidationError'

        // Custom debugging information
        this.validationMessages = validationMessages
        this.date = new Date()
    }
}

export class HandledError extends Error {
    constructor(handledError, ...params) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...params)

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ValidationError)
        }

        this.name = 'ValidationError'

        // Custom debugging information
        this.handledError = handledError
        this.date = new Date()
    }
}