import React, { Fragment } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './ShippingForm.scss';
import { LinearProgress } from '@material-ui/core';
import CommonContext, { ApiRoutes, AppNavPaths } from "../Common";
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    IconCellRenderer,
    LinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
    VariableLinkCellRenderer
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import {
    onFieldChange, AppPageForm, SmallOutlineButton, FlexCenterRow, toasty, FlexRow, SmallButton
} from '../common/forms/FormElements';
import { util } from '../Util';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Table, Button, Input } from 'reactstrap';
import { ShippingDetailDTO, ShippingDTO } from './Shipping';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons'
import { Prompt, withRouter } from 'react-router-dom';
import { handleFormSaveError } from '../common/forms/ValidationError';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';
import { v4 as uuid } from 'uuid';
class ShippingForm extends React.Component {
    static contextType = CommonContext;
    constructor(props) {
        super(props);

        const abortController = new AbortController();

        this.state = {
            loading: true,
            saving: false,
            rowData: [],
            activeTab: '1',
            rowsSelected: [],
            isExporting: false,
            capImage: null,
            shipping: new ShippingDTO(),
            canCreate: false,
            canEdit: false,
            abortController: abortController,
            errors: {},
            isNew: false,
            includedShippingLoading: false,
            isDirty: false,
            isAddingManualSalesOrder: false,
            newDetail: new ShippingDetailDTO()
        };

        this.onRowSelected = this.onRowSelected.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSaveNew = this.onSaveNew.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onRemoveFromShipping = this.onRemoveFromShipping.bind(this);
    };

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    };

    componentWillUnmount = async () => {
        const { abortController } = { ...this.state };

        abortController.abort();

        if (this._subscription) {
            await authService.unsubscribe(this._subscription);
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        let { abortController } = { ...this.state };

        if (!abortController.signal.aborted) {
            if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
                this.populateState();
            }
        }
    };

    notifySuccess = () => toasty.success('Shipping', `Successfully Saved Shipping Group`);
    notifyError = (message) => toasty.error('Save Unsuccessful', message);
    handleSaveError = (err) => handleFormSaveError(this, err);

    clearSaving = () => this.setState((state) => { return { saving: false }; });

    displaySaveErrors = (response) => this.setState((state) => { return { errorResponse: response }; });
    clearSaveErrors = () => this.setState((state) => { return { errorResponse: {} }; });

    onClearErrorNotificationClicked = e => {
        e.stopPropagation();
        this.setState({ errors: {} });
    };

    onClearWarningNotificationClicked = e => {
        e.stopPropagation();
        this.setState({ warnings: {} });
    };

    onChange = onFieldChange;

    populateState = async () => {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            let { id } = { ...this.props.match.params };
            let { shipping, canCreate, canEdit, abortController } = { ...this.state };
            const { userPermissions } = await getUserProfile();

            //this.setState({
            //    includedShippingLoading: !!id
            //});

            try {
                if (!abortController.signal.aborted) {
                    const [
                        customers,
                        shippingResp
                    ] = await Promise.all([
                        util.fetch.js(ApiRoutes.typeAheads.apteanCustomers()),
                        !!id ? util.fetch.get(ApiRoutes.shipping.byId(id), util.fetch.format.none) : new ShippingDTO(),
                    ]);

                    if (!!id && !shippingResp.ok) {
                        //Handle erroneous links entered by the user
                        if (shippingResp.status === 404)
                            this.props.history.push(AppNavPaths.NotFound);
                        else
                            this.props.history.push(AppNavPaths.ServerError);
                        return false;
                    } else {
                        if (!!id) {
                            let shippingRaw = await shippingResp.json();

                            shipping = shippingRaw.data;
                            //if (!!shipping.salesOrderTrackingIds?.length) {

                            //    util.fetch
                            //        .post(ApiRoutes.shipping.shippingsalesorders(id))
                            //        .then(async (data) => {
                            //            this.setState({
                            //                includedShipping: data.rows,
                            //                includedShippingLoading: false
                            //            });
                            //        })
                            //        .catch((error) => {
                            //            console.error(error);
                            //        });
                            //}
                        }
                    }

                    const gridOptions = createGridOptions(this);

                    gridOptions.customParametersFunction = () => {
                        let { shipping } = { ...this.state };
                        if (!!shipping.shippingDetails?.length) {

                            let salesOrderTrackingIds = shipping.shippingDetails
                                .filter((sd) => sd.salesOrderTracking_Id != null)
                                .map((sd) => sd.salesOrderTracking_Id);

                            return {
                                NotIncluding: [...salesOrderTrackingIds]
                            };
                        }
                    };

                    gridOptions.cacheBlockSize = 30;

                    gridOptions.components = {
                        selectFilter: DataGridSelectFilter,
                        selectFloatingFilter: DataGridSelectFloatingFilter,
                        nameRenderer: LinkCellRenderer,
                        iconRenderer: IconCellRenderer,
                        variableLinkRenderer: VariableLinkCellRenderer
                    };
                    gridOptions.onRowSelected = this.onRowSelected;
                    gridOptions.rowSelection = 'multiple'
                    gridOptions.rowMultiSelectWithClick = true;
                    gridOptions.columnDefs = this.getColumnDefs();
                    gridOptions.useLoading = true;
                    gridOptions.loadingTemplate = '<span className="ag-overlay-loading-center "><i class="faSaveSpinner fa fa-md fa-circle-notch"></i> Loading ...</span>';

                    const dataSource = createDataSource(ApiRoutes.shipping.getSalesOrders(), gridOptions);

                    canCreate = userPermissions.includes("shipping.create");
                    canEdit = userPermissions.includes("shipping.edit");

                    this.setState({
                        loading: false,
                        gridOptions: gridOptions,
                        dataSource: dataSource,
                        customers: customers,
                        shipping: shipping,
                        canCreate: canCreate,
                        canEdit: canEdit,
                        isNew: !!!id,
                        saving: false,
                        isDirty: false
                    });
                }
            } catch (err) {
                if (!(err instanceof DOMException)) {
                    console.log(err);
                } else {
                    if (err.name != "AbortError") {
                        console.log(err);
                    }
                }
            }
        }
    };

    onCloseClicked = () => {
        this.props.history.push(`${AppNavPaths.Shipping}`);
    };

    getColumnDefs() {

        //https://www.ag-grid.com/documentation/javascript/column-definitions/

        let customerFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Customer',
            options: this.state.customers,
            optionsLabel: 'label',
            optionsValue: 'value',
        };        

        const defs = [
            {
                headerName: "",
                valueGetter: "node.id",
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                minWidth: 35,
                cellRenderer: this.indexCellRenderer
            },
            {
                colId: 'RequiredDate',
                headerName: 'Required Date',
                field: 'requiredDate',
                sortable: true,
                maxWidth: 120,
                minWidth: 120,
                sort: 'asc',
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults
            },
            {
                colId: 'Item_ID_And_Rev',
                headerName: 'Serial#',
                field: 'serialNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'Quote.Number',
                headerName: 'Quote#',
                field: 'quoteNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'ItemMaster.SalesOrders.First().Sales_Order_ID',
                headerName: 'SalesOrder#',
                field: 'salesOrderNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'ItemMaster.SalesOrders.First().Customer.Customer_Name',
                headerName: 'Dealer/Customer',
                field: 'customer',
                sortable: true,
                flex: 1.5,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: customerFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: customerFilterParams
            },
            {
                colId: 'ItemMaster.Item_Name',
                headerName: 'Model',
                field: 'itemName',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            }
        ];

        return defs;
    };

    async toggle(tab) {
        let { gridOptions } = { ...this.state };

        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                asyncActive: true
            });

            if (tab == '2') {
                if (!this.state.gridOptions.isLoading) {
                    gridOptions.refresh();
                }
            }
        }
    };

    //https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    //Possibly use lodash dequeue?
    onRowSelected(e) {
        const rs = e.api.getSelectedRows();
        this.setState({
            rowsSelected: rs
        });
    };

    onRemoveFromShipping = async (shp) => {
        let { shipping } = { ...this.state };
        try {

            if ((shipping.shippingDetails ?? []).length) {

                let idx = shipping.shippingDetails.indexOf(shp);

                shipping.shippingDetails.splice(idx, 1);

                //do removel
                this.setState({
                    shipping: shipping,
                    isDirty: true
                });
            }
            
        } catch (error) {
            this.notifyError(error.toString());
        }
    };

    onAddToShipping = () => {
        let { rowsSelected, shipping, gridOptions } = { ...this.state };

        let salesOrderTrackingIds = shipping.shippingDetails
            .filter((sd) => sd.salesOrderTracking_Id != null)
            .map((sd) => sd.salesOrderTracking_Id);

        let newSlsRows = rowsSelected.filter((rs) => !salesOrderTrackingIds.includes(rs.id));

        if (newSlsRows.length) {
            let newSlsOrds = newSlsRows.map((rw) => {
                var newDetail = new ShippingDetailDTO();
                newDetail.salesOrderTracking_Id = rw.id;
                newDetail.serialNumber = rw.serialNumber;
                newDetail.quoteNumber = rw.quoteNumber;
                newDetail.salesOrderNumber = rw.salesOrderNumber;
                newDetail.dealerCustomer = rw.customer;
                newDetail.model = rw.itemName;
                newDetail.requiredDate = new Date(rw.requiredDate);
                return newDetail;
            });

            if ((shipping.shippingDetails ?? []).length) {
                //dedupe and spread
                shipping.shippingDetails = [
                    ...(new Set([
                        ...shipping.shippingDetails,
                        ...newSlsOrds
                    ]))
                ];
            } else {
                shipping.shippingDetails = newSlsOrds;
            }

        }

        gridOptions.api.deselectAll();

        this.setState({
            rowsSelected: [],
            shipping: shipping,
            activeTab: '1',
            isDirty: true
        });
    };

    onSaveNew = async () => {
        this.setState({ saving: true });
        let { shipping } = { ...this.state };

        try {
            let response = await util.fetch.post(ApiRoutes.shipping.base(), shipping, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.notifySuccess();
                    var shippingId = serviceResponse.data;
                    this.props.history.push(`${AppNavPaths.Shipping}/${shippingId}`);
                } else {
                    this.notifyError();
                    let sResponse = {
                        title: 'Server Error',
                        errors: {
                            Exception: [
                                serviceResponse.message
                            ]
                        }
                    }
                    this.displaySaveErrors(sResponse);
                    this.clearSaving();
                }
            } else {

                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {
                    let serviceResponse = {
                        title: 'Server Error',
                        errors: {
                            Exception: [
                                await response.text()
                            ]
                        }
                    }
                    this.displaySaveErrors(serviceResponse);
                }
                this.clearSaving();
            }
        } catch (error) {
            this.notifyError(error.toString());
            this.clearSaving();
        } 
    };

    onSave = async () => {
        await this.setState({ saving: true });
        let { shipping } = { ...this.state };

        try {
            let response = await util.fetch.put(ApiRoutes.shipping.byId(shipping.id), shipping, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.notifySuccess();
                    this.populateState();
                } else {
                    this.notifyError();
                    let sResponse = {
                        title: 'Server Error',
                        errors: {
                            Exception: [
                                serviceResponse.message
                            ]
                        }
                    }
                    this.displaySaveErrors(sResponse);
                    this.clearSaving();
                }
            } else {

                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {
                    let serviceResponse = {
                        title: 'Server Error',
                        errors: {
                            Exception: [
                                await response.text()
                            ]
                        }
                    }
                    this.displaySaveErrors(serviceResponse);
                }
                this.notifyError();
                this.clearSaving();
            }
        } catch (error) {
            this.notifyError(error.toString());
            this.clearSaving();
        }
    };

    finishManualDetail = () => {
        let { newDetail, shipping } = { ...this.state };

        if (!!!newDetail.serialNumber || !!!newDetail.quoteNumber
            || !!!newDetail.salesOrderNumber || !!!newDetail.dealerCustomer
            || !!!newDetail.model || !!!newDetail.requiredDate
        ) {
            this.notifyError("All Manual fields are required");
            return;
        }

        shipping.shippingDetails.push(newDetail);

        this.setState({
            isAddingManualSalesOrder: false,
            newDetail: new ShippingDetailDTO(),
            shipping: shipping,
            isDirty: true
        });
    };

    render() {
        const {
            rowData,
            gridOptions,
            canEdit,
            canCreate,
            loading,
            errors,
            shipping,
            includedShippingLoading,
            isNew,
            rowsSelected,
            isDirty,
            errorResponse
        } = this.state;
        let {
            isAddingManualSalesOrder,
            newDetail
        } = this.state;
        return (
            <CommonContext.Consumer>
                {
                    value => {
                        if (this.state.loading) return (<LinearProgress variant="indeterminate" color="secondary" />);

                        return (
                            <Fragment>
                                <AppPageForm
                                    formId={"shippingForm"}
                                    formHeadingIcon="fa-truck"
                                    formHeading={isNew ? 'New Shipping' : `Shipping: 'S-'${shipping.shippingNumber.replace(/^0+/, "")}`}
                                    formName={"shippingForm"}
                                    formRef={this.formRef}
                                    onSubmit={this.onSubmit}
                                    errors={errors}
                                    loading={loading}
                                    saving={this.state.saving}
                                    onClearErrors={this.onClearErrorNotificationClicked}
                                    onClearWarnings={this.onClearWarningNotificationClicked}
                                    showThisValidation={false}
                                    ValidationBottom={true}
                                >
                                    <Row>
                                        <Col sm={12} md={12} lg={12}>
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === '1' })}
                                                        onClick={async (event) => {
                                                            event.persist();
                                                            await this.toggle('1');
                                                        }}
                                                    >Included Sales Orders</NavLink>
                                                </NavItem>
                                                { ((canEdit || canCreate) && !shipping.isComplete) &&
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activeTab === '2' })}
                                                            onClick={async (event) => {
                                                                event.persist();
                                                                await this.toggle('2');
                                                            }}
                                                        >Add Sales Orders</NavLink>
                                                    </NavItem>
                                                }
                                            </Nav>
                                            <TabContent activeTab={this.state.activeTab} style={{paddingTop: '1em'}} >
                                                <TabPane tabId="1">
                                                    <div style={{ marginBottom: '1em', display: 'flex', flexDirection: 'row-reverse' }} >
                                                        <SmallOutlineButton
                                                            onClick={() => { this.setState({ isAddingManualSalesOrder: true }) }}
                                                            className="float-right"
                                                        >
                                                            <i className="fa fa-plus-square fa-lg" />
                                                            <span className="ml-2 small-viewport-hide">
                                                                Manual Add
                                                            </span>
                                                        </SmallOutlineButton>
                                                    </div>
                                                    <Table striped style={{ borderTop: "4px solid" }}>
                                                        <colgroup>
                                                            <col style={{ width: '16%' }}></col>
                                                            <col style={{ width: '16%' }}></col>
                                                            <col style={{ width: '16%' }}></col>
                                                            <col style={{ width: '20%' }}></col>
                                                            <col style={{ width: '11%' }}></col>
                                                            <col style={{ width: '16%' }}></col>
                                                            <col style={{ width: '5%' }}></col>
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                <th>Serial#</th>
                                                                <th>Quote#</th>
                                                                <th>Sales Order#</th>
                                                                <th>Dealer/Customer</th>
                                                                <th>Model</th>
                                                                <th>Required Date</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!includedShippingLoading && !!(shipping.shippingDetails ?? []).length &&
                                                                shipping.shippingDetails.sort(
                                                                    (s1, s2) => s1.serialNumber < s2.serialNumber
                                                                ).map((shp) =>
                                                                    <tr key={uuid()} >
                                                                        <td>{shp.serialNumber}</td>
                                                                        <td>{shp.quoteNumber}</td>
                                                                        <td>{shp.salesOrderNumber}</td>
                                                                        <td>{shp.dealerCustomer}</td>
                                                                        <td>{shp.model}</td>
                                                                        <td>{new Date(shp.requiredDate).toLocaleDateString("en-US")}</td>
                                                                        <td>
                                                                            {((canEdit || canCreate) && !shipping.isComplete) &&
                                                                                <SmallButton
                                                                                    type="button"
                                                                                    onClick={() => this.onRemoveFromShipping(shp)}
                                                                                >
                                                                                    <i className={`fa fa-trash fa-md mr-2`} />
                                                                                </SmallButton>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            {!includedShippingLoading && !!!(shipping.shippingDetails ?? []).length &&
                                                                <tr>
                                                                    <td colSpan={7} style={{ textAlign: "center" }} >No Sales Orders</td>
                                                                </tr>
                                                            }
                                                            {includedShippingLoading && 
                                                                <tr>
                                                                    <td colSpan={7} style={{ textAlign: "center" }} >
                                                                        <span className="ag-overlay-loading-center "><i className="faSaveSpinner fa fa-md fa-circle-notch"></i> Loading ...</span>
                                                                    </td>
                                                                </tr>
                                                            }
                                                            {isAddingManualSalesOrder && !includedShippingLoading &&
                                                                <tr className="manual-entry-row" >
                                                                    <td>
                                                                        <Input
                                                                            type="text"
                                                                            id="newDetail.serialNumber"
                                                                            name="newDetail.serialNumber"
                                                                            value={newDetail.serialNumber ?? ""}
                                                                            onChange={this.onChange}                                                                            
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type="text"
                                                                            id="newDetail.quoteNumber"
                                                                            name="newDetail.quoteNumber"
                                                                            value={newDetail.quoteNumber ?? ""}
                                                                            onChange={this.onChange}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type="text"
                                                                            id="newDetail.salesOrderNumber"
                                                                            name="newDetail.salesOrderNumber"
                                                                            value={newDetail.salesOrderNumber ?? ""}
                                                                            onChange={this.onChange}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type="text"
                                                                            id="newDetail.dealerCustomer"
                                                                            name="newDetail.dealerCustomer"
                                                                            value={newDetail.dealerCustomer ?? ""}
                                                                            onChange={this.onChange}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type="text"
                                                                            id="newDetail.model"
                                                                            name="newDetail.model"
                                                                            value={newDetail.model ?? ""}
                                                                            onChange={this.onChange}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <DatePicker selected={newDetail.requiredDate}
                                                                            autoComplete='off'
                                                                            id="requiredDate"
                                                                            name="newDetail.requiredDate"
                                                                            className="form-control"
                                                                            onChange={
                                                                                (date) => {
                                                                                    newDetail.requiredDate = date;
                                                                                    this.setState({ newDetail: newDetail });
                                                                                }
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <SmallButton
                                                                            type="button"
                                                                            onClick={() => this.finishManualDetail() }
                                                                        >
                                                                            <i className={`fa fa-check-circle fa-md mr-2`} />
                                                                        </SmallButton>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </TabPane>
                                                {!shipping.isComplete &&
                                                    <TabPane tabId="2">
                                                        <Row>
                                                            <Col sm={12} md={12} lg={12}>
                                                                {!!(rowsSelected ?? []).length &&
                                                                    <SmallOutlineButton onClick={() => { this.onAddToShipping(); }} className="float-right">
                                                                        <i className="fa fa-plus-square fa-lg" />
                                                                        <span className="ml-2 small-viewport-hide">
                                                                            Add To Shipping
                                                                        </span>
                                                                    </SmallOutlineButton>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12} md={12} lg={12}>
                                                                <DataGridToolbar
                                                                    entity="SalesOrderTracking"
                                                                    gridApi={this.state.gridApi}
                                                                    dataSource={this.state.dataSource}
                                                                    hideAdd={true}
                                                                    hideExcelButton={true}
                                                                    gridOptions={this.state.gridOptions}
                                                                />
                                                                <DataGrid
                                                                    domLayout={"normal"}
                                                                    rowData={rowData}
                                                                    gridOptions={gridOptions}
                                                                    gridStatus={this.state.gridStatus}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </TabPane>
                                                }
                                            </TabContent>
                                        </Col>
                                    </Row>
                                    <FlexCenterRow className="mt-3 mb-3">
                                        {isNew && !!((shipping.shippingDetails ?? []).length)
                                            && !isAddingManualSalesOrder &&
                                            <Button
                                                size="sm"
                                                type="button"
                                                color="secondary"
                                                disabled={this.state.saving}
                                                name="createShipping"
                                                onClick={this.onSaveNew}
                                                className="ml-2">
                                                <FontAwesomeIcon
                                                    className="mr-2"
                                                    icon={faSave} />
                                                {"Create"}
                                            </Button>
                                        }
                                        {isDirty && !isNew && !!((shipping.shippingDetails ?? []).length)
                                            && !isAddingManualSalesOrder &&
                                            <Button
                                                size="sm"
                                                type="button"
                                                color="secondary"
                                                disabled={this.state.saving}
                                                name="saveShipping"
                                                onClick={this.onSave}
                                                className="ml-2">
                                                <FontAwesomeIcon
                                                    className="mr-2"
                                                    icon={faSave} />
                                                {"Save"}
                                            </Button>
                                        }
                                        <Button
                                            size="sm"
                                            type="button"
                                            color="secondary"
                                            disabled={this.state.saving}
                                            name="workCenterFormClose"
                                            onClick={this.onCloseClicked}
                                            className="ml-2">
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faTimes} />
                                            {"Close"}
                                        </Button>
                                    </FlexCenterRow>
                                    <FlexRow>
                                        <FormErrorResponseDisplay onClear={this.clearSaveErrors} response={errorResponse} />
                                    </FlexRow>
                                </AppPageForm>
                                <Prompt
                                    when={!this.state.saving && isDirty}
                                    message='You have unsaved changes, are you sure you want to leave?'
                                />
                            </Fragment>
                        )
                    }
                }
            </CommonContext.Consumer>
        )
    }
}

export default withRouter(ShippingForm);
