import React from 'react'
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

export class NoPermission extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
        };
    }
    componentDidMount() {
        
    }

    render() {
        return (
            <div className="page-wrap d-flex flex-row align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <span className="display-1 d-block">Access Denied</span>
                            <div className="mb-4 lead">The current user does not have permission to view this page.</div>
                            <a href="/" className="btn btn-link">Back to Home</a>
                        </div>
                    </div>
                </div>
            </div>
       )
    }
}
export default withRouter(NoPermission);