import React, { Fragment } from 'react';

//Contexts
import { FlexRow, onFieldChange, toasty } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { ModifyQuestionGroup, QuestionOption, QuestionOptionGroup } from './FormsAndSurveys';
import { isEqual } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes, faTasks, faArrowAltCircleUp, faArrowAltCircleDown, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import {
    AppPageForm,
    FlexCenterRow,
    FormGroupColumn,
    FormLabel,
    GroupedRow
} from '../common/forms/FormElements';

import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';

import {
    Button,
    FormGroup,
    Input,
    Table,
    Label
} from 'reactstrap';

import { Prompt, withRouter } from 'react-router-dom';
import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';
import ValidatedSelect from '../common/forms/ValidatedSelect';

//#endregion

class QuestionOptionGroupForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            //general form state
            formOpened: false,
            formValidated: false,
            loading: true,
            saving: false,
            errorResponse: {},
            //Form Specific state
            modgroup: new ModifyQuestionGroup(),
            questionoptions: [],
            newOptionToGroup: null
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.save = this.save.bind(this);
        this.saveNew = this.saveNew.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            let { id } = { ...this.props.match.params };
            const { userPermissions } = await getUserProfile();

            if (id == 'new') {
                id = undefined; //If the Id is identified as being a new UOM, then just kill the id so we don't call the API
            }

            var [groupResponse, questionOptions] = await Promise.all([
                !!id ? util.fetch.post(ApiRoutes.questionoptiongroups.byid(id), util.fetch.format.none) : new ModifyQuestionGroup(),
                util.fetch.js(ApiRoutes.questionoptions.all())
            ]);

            var group;

            //Handle any issues fetching data
            if (!!id && !!groupResponse.error) {
                //Handle erroneous links entered by the user
                let serviceResponse = {
                    title: 'Server Error',
                    errors: {
                        Exception: [
                            groupResponse.error
                        ]
                    }
                }
                this.displaySaveErrors(serviceResponse);
                return false;
            } else {
                group = groupResponse;
            }

            //If there are any option groups, we need to remove them
            //from the options that can be added
            if (group.optionGroups.length) {

                let optionIds = group.optionGroups.map(og => og.questionOptionId);
                //filter out options that are already mapped
                questionOptions = questionOptions.filter(o => !optionIds.includes(o.id));
            }

            this.setState({
                formValidated: false,
                saving: false,
                loading: false,
                perms: userPermissions,
                originalData: group,
                modgroup: group,
                questionoptions: questionOptions
            });
        }
    }

    onChoiceRemoved = async (index) => {
        let { modgroup, questionoptions } = { ...this.state };

        let removeGroup = modgroup.optionGroups[index];
        modgroup.optionGroups.splice(index, 1);

        if (modgroup.optionGroups.length) {
            let neworder = removeGroup.order;
            let remainingOptions = modgroup.optionGroups.filter(og => og.order > neworder).sort((og1, og2) => og1.order < og2.order ? -1 : 1);
            if (remainingOptions.length) {
                remainingOptions.forEach(og => og.order = neworder++);
            }
        }

        let option = modgroup.options.filter(o => o.id == removeGroup.questionOptionId)[0];
        let oind = modgroup.options.indexOf(option);
        modgroup.options.splice(oind, 1);

        //put the option back into the pool of options that can be added
        let addOption = new QuestionOption();
        addOption.id = removeGroup.questionOptionId;
        addOption.choiceText = removeGroup.questionOptionText;
        questionoptions.push(addOption);

        this.setState({
            modgroup: modgroup,
            questionoptions: questionoptions
        });
    }

    onChoiceConfirmed = async () => {
        let { modgroup, newOptionToGroup, questionoptions } = { ...this.state };

        let newoptiongroup = new QuestionOptionGroup();
        if ((modgroup.optionGroups ?? []).length > 0) {
            newoptiongroup.order = Math.max(...modgroup.optionGroups.map(o => o.order))+1;
        } else {
            newoptiongroup.order = 1;
        }

        newoptiongroup.questionOptionId = newOptionToGroup.id;
        newoptiongroup.questionOptionText = newOptionToGroup.choiceText;

        modgroup.optionGroups.push(newoptiongroup);

        modgroup.options.push(newOptionToGroup);

        let oind = questionoptions.indexOf(newOptionToGroup);
        questionoptions.splice(oind, 1);

        this.setState({
            modgroup: modgroup,
            newOptionToGroup: null,
            questionoptions: questionoptions
        });
    }

    onChoiceAdded = async (newchoice) => {      
        this.setState({
            newOptionToGroup: newchoice
        });
    }

    onCreateNewOption = async (choiceText) => {
        this.clearSaving()
        this.setState({ errors: {}, saving: true });

        let { questionoptions } = { ...this.state };
        var newOpt = new QuestionOption();
        newOpt.choiceText = choiceText;

        try {
            let response = await util.fetch.post(ApiRoutes.questionoptions.create(), newOpt, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                newOpt.Id = await response.json();
                questionoptions.push(newOpt);

                this.setState({
                    questionoptions: questionoptions
                });

                this.notifySuccess();
            } else {
                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {

                    let errorResp = await response.json();

                    if (errorResp.errors) {
                        this.displaySaveErrors(errorResp);
                    } else {
                        let serviceResponse = {
                            title: 'Server Error',
                            errors: {
                                Exception: [
                                    await response.text()
                                ]
                            }
                        }
                        this.displaySaveErrors(serviceResponse);
                    }
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    moveOptionUp = async (index) => {
        let { modgroup } = { ...this.state };

        if (index != 0) {
            let tomove = modgroup.optionGroups[index];
            tomove.order = tomove.order - 1;
            let sibling = modgroup.optionGroups[index - 1];
            sibling.order = sibling.order + 1;

            modgroup.optionGroups[index - 1] = tomove;
            modgroup.optionGroups[index] = sibling;

            this.setState({
                modgroup: modgroup
            });
        }
    }

    moveOptionDown = async (index) => {
        let { modgroup } = { ...this.state };

        if (index != modgroup.optionGroups.length) {
            let tomove = modgroup.optionGroups[index];
            tomove.order = tomove.order + 1;
            let sibling = modgroup.optionGroups[index + 1];
            sibling.order = sibling.order - 1;

            modgroup.optionGroups[index + 1] = tomove;
            modgroup.optionGroups[index] = sibling;

            this.setState({
                modgroup: modgroup
            });
        }
    }

    //#region METHODS
    onChange = onFieldChange;

    onSubmit() {
        let { modgroup } = { ...this.state };
        this.clearSaving()
        this.setState({ errors: {}, saving: true });

        if (this.props.location.pathname === AppNavPaths.QuestionGroupsNew) {
            this.saveNew(modgroup);
        } else {
            this.save(modgroup);
        }
    }

    clearSaving = () => this.setState({ saving: false });

    notifySuccess = () => toasty.success('Question Group Saved', `Question Group saved successfully.`);
    notifyError = (message) => toasty.error('Save Unsuccessful', message);
    handleSaveError = (err) => handleFormSaveError(this, err);

    displaySaveErrors = (response) => this.setState({ errorResponse: response });
    clearSaveErrors = () => this.setState({ errorResponse: {} });

    onCloseClicked = () => {
        this.props.history.push(AppNavPaths.QuestionGroups);
    }

    saveNew = async (modgroup) => {
        try {
            let response = await util.fetch.post(ApiRoutes.questionoptiongroups.create(), modgroup, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                let groupId = await response.json();
                this.notifySuccess();
                this.props.history.push(`${AppNavPaths.QuestionGroups}/${groupId}`);
            } else {
                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {

                    let errorResp = await response.json();

                    if (errorResp.errors) {
                        this.displaySaveErrors(errorResp);
                    } else {
                        let serviceResponse = {
                            title: 'Server Error',
                            errors: {
                                Exception: [
                                    await response.text()
                                ]
                            }
                        }
                        this.displaySaveErrors(serviceResponse);
                    }
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    save = async (modgroup) => {
        try {
            let response = await util.fetch.put(ApiRoutes.questionoptiongroups.byid(modgroup.group.id), modgroup, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                this.notifySuccess();
                this.populateState();
            } else {
                this.displaySaveErrors(await response.json());
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    //#endregion

    //#region RENDERING
    render() {
        const { loading, perms, originalData, modgroup, questionoptions, newOptionToGroup } = { ...this.state };
        let { errorResponse } = { ...this.state };

        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {
            const isNew = ((modgroup.group.id ?? 0) <= 0);
            const saveButtonText = (!isNew ? 'Save' : 'Save New Group');

            //field editing permissions
            const canEdit = !!perms.includes("questionoption.edit") || (isNew && !!perms.includes("questionoption.create"));

            return (
                <Fragment>
                    <AppPageForm
                        formId={"questionGroupForm"}
                        formHeadingIcon={faTasks}
                        formHeading={isNew ? 'New Question Option Group' : 'Edit Question Option Group'}
                        formName={"questionGroupForm"}
                        formRef={this.formRef}
                        onSubmit={this.onSubmit}
                        setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                        isValidated={this.state.formValidated}
                        saving={this.state.saving}
                        errors={this.state.errors}
                        loading={this.state.loading}
                    >
                        <GroupedRow>
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel htmlFor="inputName" text="Group Name" required={true} />
                                    <input id="inputName"
                                        name="modgroup.group.name"
                                        autoComplete="off"
                                        className="form-control"
                                        required
                                        disabled={!isNew && !canEdit}
                                        onChange={!!canEdit ? this.onChange : undefined}
                                        defaultValue={modgroup.group.name} />
                                    <small className="invalid-feedback text-danger" hidden>Group Name is required.</small>
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        id="inputisExclusiveChoice"
                                        name="modgroup.group.isExclusiveChoice"
                                        autoComplete="off"
                                        className="form-control"
                                        disabled={!isNew && !canEdit}
                                        onChange={!!canEdit ? this.onChange : undefined}
                                        type="checkbox"
                                        checked={modgroup.group.isExclusiveChoice}
                                    />
                                    <Label style={{color: "#777", fontWeight: "600", fontSize: ".9em", lineHeight: "45px" }}
                                        check htmlFor="inputisExclusiveChoice" >
                                        {"Exclusive Choice?"}
                                    </Label>
                                </FormGroup>
                            </FormGroupColumn>
                        </GroupedRow>
                        <FlexCenterRow>
                            <Table style={{ width: "100%" }} >
                                <colgroup>
                                    <col style={{ width: "10%" }} />
                                    <col style={{ width: "70%" }} />
                                    <col style={{ width: "20%" }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>Order</th>
                                        <th>Choice</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!!(modgroup.optionGroups ?? []).length && modgroup.optionGroups.map((og, ind) =>
                                        <tr key={og.questionOptionText + og.questionOptionId}>
                                            <td>
                                                {(og.order != 1) && < FontAwesomeIcon
                                                    className="mr-2"
                                                    onClick={() => this.moveOptionUp(ind)}
                                                    icon={faArrowAltCircleUp} />
                                                }
                                                {(og.order != modgroup.optionGroups.length) &&
                                                    <FontAwesomeIcon
                                                        className="mr-2"
                                                        onClick={() => this.moveOptionDown(ind)}
                                                        icon={faArrowAltCircleDown} />
                                                }
                                            </td>
                                            <td>{og.questionOptionText}</td>
                                            <td>
                                                <FontAwesomeIcon
                                                    className="mr-2"
                                                    onClick={() => this.onChoiceRemoved(ind)}
                                                    icon={faTrashAlt} />
                                            </td>
                                        </tr>
                                    )}
                                    {!!!(modgroup.optionGroups ?? []).length && 
                                        <tr>
                                            <td colSpan={3} >
                                                {"No Choices"}
                                            </td>
                                        </tr>
                                    }
                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <FormGroup>
                                                <ValidatedSelect
                                                    id="inputAddChoice"
                                                    isDisabled={!canEdit}
                                                    options={questionoptions}
                                                    value={newOptionToGroup}
                                                    getOptionLabel={option => option.choiceText}
                                                    getOptionValue={option => option.id}
                                                    onChange={!!canEdit ? this.onChoiceAdded : undefined}
                                                    validationMessage=""
                                                />
                                                <small className="invalid-feedback text-danger" hidden>Whoops</small>
                                            </FormGroup>
                                        </td>
                                        <td>
                                            {!!newOptionToGroup &&
                                                <Button
                                                    size="sm"
                                                    type="button"
                                                    color="primary"
                                                    onClick={this.onChoiceConfirmed}
                                                    name="addChoice">
                                                    <FontAwesomeIcon
                                                        className="mr-2"
                                                        icon={faSave} />
                                                    {"Add Choice"}
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </FlexCenterRow>
                        <FlexCenterRow className="mb-3">
                            {!!canEdit &&
                                <Button
                                    size="sm"
                                    type="submit"
                                    color="primary"
                                    disabled={this.state.saving}
                                    name="saveButton">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSave} />
                                    {saveButtonText}
                                </Button>
                            }
                            <Button
                                size="sm"
                                type="button"
                                color="secondary"
                                name="closeButton"
                                onClick={this.onCloseClicked}
                                className="ml-2">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faTimes} />
                                {"Close"}
                            </Button>
                        </FlexCenterRow>
                        <FlexRow>
                            <FormErrorResponseDisplay onClear={this.clearSaveErrors} response={errorResponse} />
                        </FlexRow>
                    </AppPageForm>
                    <Prompt
                        when={!this.state.saving && !isEqual(originalData, modgroup)}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />
                </Fragment>
            );
        }

    }
    //#endregion
}

export default withRouter(QuestionOptionGroupForm);