import React, { Fragment } from 'react';
import moment from 'moment';
import "./SalesOrderTrackingForm.scss";
import { Tooltip } from '@material-ui/core';
import { BaseFormViewModel } from '../common/ViewModel';
import {
    CreateSalesOrderModel, DocumentTypes, SalesOrderTrackingWorkOrderDTO, ChassisInfoDTO
} from '../workflow/SalesOrderTracking';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    FormGroup,
    Button,
    Input,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardText,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import {
    FormLabel, onFieldChange, onReactSelectChanged, toasty, GroupedRow, FormGroupColumn, AppPageForm, SmallButton
} from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import Dropzone from 'react-dropzone';
import FileLink from '../common/forms/FileLink';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid'
import authService from '../api-authorization/AuthorizeService';
import { sortBy } from 'lodash-es';
import { Can } from '../Can';
import { getUserProfile } from '../common/UserProfile';
//Survey Display
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    LinkCellRenderer,
    TextFilterDefaults,
    ConditionalPureIconCellRenderer,
    IndexCellRendererWithEdit
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import { Progress } from 'reactstrap';
//Suports the font-awesome icon picker
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons_sol from '@fortawesome/free-solid-svg-icons';
import * as Icons_reg from '@fortawesome/free-regular-svg-icons';

const iconList_reg = Object
    .keys(Icons_reg)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons_reg[icon])

library.add(...iconList_reg)

const iconList_sol = Object
    .keys(Icons_sol)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons_sol[icon])

library.add(...iconList_sol)

//End icon picker support

export default class SalesOrderTrackingForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        const abortController = new AbortController();

        let stateBase = Object.assign(
            {
                salesorderTrackingId: null,
                addByMethod: 0,
                trackableSO: [],
                chosenSO: "",
                salesOrderTracking: {},
                customers: [],
                chosenCust: "",
                prodFams: [],
                chosenProdFam: "",
                prodCats: [],
                chosenProdCat: "",
                fileCreation: new CreateSalesOrderModel(),
                activeTab: '1',
                showSequenceModal: false,
                sequenceData: new SalesOrderTrackingWorkOrderDTO(),
                liftTypes: [],
                chosenLiftType: null,
                isNestedInSlideForm: false, //used to make rendering changes specific to being nested in a SlideForm
                quote: null,
                quoteLines: [],
                workFlowStatusTags: [],
                workflowTagsToAdd: [],
                workflowTagsToRemove: [],
                tagsSaving: false,
                asyncActive: false,
                hasLoaded: {
                    liftOptions: false,
                    mountingInstructions: false,
                    drawings: false,
                    mediaItems: false,
                    sourceDocuments: false,
                    workCenterForms: false,
                    workflowTags: false,
                    quote: false,
                    workOrders: false
                },
                abortController: abortController,
                workCenterId: null,
                nestedProcessing: false
            },
            new BaseFormViewModel()
        );

        this.state = stateBase;
        this.onChange = this.onChange.bind(this);
        this.onSelectChanged = this.onSelectChanged.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleSequence = this.toggleSequence.bind(this);
    }

    componentDidMount = async () => {
        const { id } = { ...this.props.match.params };
        const { isNestedInSlideForm, isSlideFormOpen } = { ...this.props }

        this._subscription = authService.subscribe(() => this.populateState());
        await this.populateState();

        if ((!!id && !isNestedInSlideForm) || (isNestedInSlideForm && isSlideFormOpen)) {
            await this.setState({
                asyncActive: true
            });
            await this.loadQuote();
            await this.loadWorkOrders();
        }
    }

    componentWillUnmount = async () => {
        const { abortController } = { ...this.state };

        abortController.abort();

        if (this._subscription) {
            await authService.unsubscribe(this._subscription);
        }
    }

    componentDidUpdate = async (prevProps, prevState) => {
        const { id } = { ...this.props.match.params };
        const { isNestedInSlideForm, isSlideFormOpen } = { ...this.props }
        let { abortController, nestedProcessing, hasLoaded } = { ...this.state };

        if (!abortController.signal.aborted) {

            if ((prevProps && (
                (this.props.match.params.id !== (prevProps.match.params ?? {}).id)
                || (this.props.match.params.addByMethod !== (prevProps.match.params ?? {}).addByMethod)
                || (this.props.match.params.quoteId !== (prevProps.match.params ?? {}).quoteId)
            ))
            ) {
                await this.populateState();

                if (!!id ) {
                    this.setState({
                        asyncActive: true
                    });
                    await this.loadQuote();
                    await this.loadWorkOrders();
                }
            }

            if (isNestedInSlideForm && isSlideFormOpen && !nestedProcessing && !hasLoaded.workOrders && !hasLoaded.quote) 
            {
                await this.setState({
                    asyncActive: true,
                    nestedProcessing: true
                });

                await this.populateState();
                
                await this.loadQuote();
                await this.loadWorkOrders();

                await this.setState({
                    asyncActive: true,
                    nestedProcessing: false
                });
            }            
        }
    }

    async toggle(tab) {
        let { hasLoaded } = { ...this.state };

        
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                asyncActive: true
            });

            if (tab == 1 && !hasLoaded.liftOptions) {
                await this.loadLiftOptions();
            }

            if (tab == 2 && !hasLoaded.mountingInstructions) {
                await this.loadMountingInstructions();
            }

            if (tab == 3 && !hasLoaded.drawings) {
                await this.loadDrawings();
                await this.loadMediaItems();
            }

            //4 is work orders
            //if (tab === 4 ) {

            //}

            if (tab == 5 && !hasLoaded.sourceDocuments) {
                await this.loadSourceDocuments();
            }

            if (tab == 6 && !hasLoaded.workCenterForms) {
                //search surveys here
                await this.createSurveySearchGrid();
            }

            if (tab == 7 && !hasLoaded.workflowTags) {
                await this.loadWorkflowTags();
            }


        }
        
    }

    toggleSequence(data) {
        this.setState({
            sequenceData: data,
            showSequenceModal: !this.state.showSequenceModal
        });
    }

    async loadWorkflowTags() {
        let { hasLoaded, abortController, salesOrderTracking } = { ...this.state };
        const { id } = { ...this.props.match.params };

        if (!abortController.signal.aborted) {

            let [
                workflowtagsResp
                , sotWorkFlowTags
            ] = await Promise.all([
                util.fetch.get(
                    ApiRoutes.SalesOrderTracking.components.workflowtags(id),
                    util.fetch.format.none,
                    { signal: abortController.signal }
                ),
                util.fetch.js(
                    ApiRoutes.SalesOrderTracking.components.baseWorkflowTags(id),
                    { signal: abortController.signal }
                )
            ]);

            var workFlowTags = [];

            if (workflowtagsResp.ok && !workflowtagsResp.url.includes('Denied')) {
                let rawTags = await workflowtagsResp.json();
                workFlowTags = rawTags.tags;
                workFlowTags = sortBy(workFlowTags, ['status']);
            }

            if (sotWorkFlowTags?.length ?? false) {
                salesOrderTracking.workFlowTags = sotWorkFlowTags;
            }

            hasLoaded.workflowTags = true;

            await this.setState({
                workFlowStatusTags: workFlowTags,
                hasLoaded: hasLoaded,
                salesOrderTracking: salesOrderTracking,
                asyncActive: false
            });
        }
    }

    async loadLiftOptions() {
        let { hasLoaded, salesOrderTracking, abortController } = { ...this.state };
        const { id } = { ...this.props.match.params };

        if (!abortController.signal.aborted) {

            let [liftOptionsResp] = await Promise.all([
                util.fetch.get(
                    ApiRoutes.SalesOrderTracking.components.liftOptions(id),
                    util.fetch.format.none,
                    { signal: abortController.signal }
                )
            ]);

            var liftOptions = [];

            if (liftOptionsResp.ok && !liftOptionsResp.url.includes('Denied')) {
                liftOptions = (await liftOptionsResp.json()).liftOptions;
            }

            hasLoaded.liftOptions = true;
            salesOrderTracking.liftOptions = liftOptions;

            await this.setState({
                salesOrderTracking: salesOrderTracking,
                hasLoaded: hasLoaded,
                asyncActive: false
            });
        }
    }

    async loadMountingInstructions() {
        let { hasLoaded, salesOrderTracking, abortController } = { ...this.state };
        const { id } = { ...this.props.match.params };

        if (!abortController.signal.aborted) {
            let [mountingInstructionsResp, chassisInfoResp] = await Promise.all([
                util.fetch.get(
                    ApiRoutes.SalesOrderTracking.components.mountingInstructions(id),
                    util.fetch.format.none,
                    { signal: abortController.signal }
                ),
                util.fetch.get(
                    ApiRoutes.SalesOrderTracking.components.chassisInfo(id),
                    util.fetch.format.none,
                    { signal: abortController.signal }
                )
            ]);

            var mountingIntructions = [];
            var chassisInfo = new ChassisInfoDTO();

            if (mountingInstructionsResp.ok && !mountingInstructionsResp.url.includes('Denied')) {
                mountingIntructions = (await mountingInstructionsResp.json()).mountingInstructions;
            }

            if (chassisInfoResp.ok && !chassisInfoResp.url.includes('Denied')) {
                chassisInfo = (await chassisInfoResp.json()).chassisInfo;
            }

            hasLoaded.mountingInstructions = true;
            salesOrderTracking.mountingInstructions = mountingIntructions;
            salesOrderTracking.chassisInfo = chassisInfo;

            await this.setState({
                salesOrderTracking: salesOrderTracking,
                hasLoaded: hasLoaded,
                asyncActive: false
            });
        }
    }

    async loadDrawings() {
        let { hasLoaded, salesOrderTracking, abortController } = { ...this.state };
        const { id } = { ...this.props.match.params };

        if (!abortController.signal.aborted) {
            let [drawingsResp] = await Promise.all([
                util.fetch.get(
                    ApiRoutes.SalesOrderTracking.components.drawings(id),
                    util.fetch.format.none,
                    { signal: abortController.signal }
                )
            ]);

            var electricalDrawings = [];
            var hydraulicDrawings = [];

            if (drawingsResp.ok && !drawingsResp.url.includes('Denied')) {
                var dto = (await drawingsResp.json()).drawings;
                electricalDrawings = dto.electricalDrawings;
                hydraulicDrawings = dto.hydraulicDrawings;
            }

            hasLoaded.mediaItems = true;
            salesOrderTracking.electricalDrawings = electricalDrawings;
            salesOrderTracking.hydraulicDrawings = hydraulicDrawings;

            await this.setState({
                salesOrderTracking: salesOrderTracking,
                hasLoaded: hasLoaded,
                asyncActive: false
            });
        }
    }

    async loadMediaItems() {
        let { hasLoaded, salesOrderTracking, abortController } = { ...this.state };
        const { id } = { ...this.props.match.params };

        if (!abortController.signal.aborted) {
            let [mediaItemsResp] = await Promise.all([
                util.fetch.get(
                    ApiRoutes.SalesOrderTracking.components.mediaitems(id),
                    util.fetch.format.none,
                    { signal: abortController.signal }
                )
            ]);

            var mediaItemCategories = [];
            var mediaItems = [];

            if (mediaItemsResp.ok && !mediaItemsResp.url.includes('Denied')) {
                var dto = (await mediaItemsResp.json()).mediaItems;
                mediaItemCategories = dto.mediaItemCategories;
                mediaItems = dto.mediaItems;
            }

            hasLoaded.drawings = true;
            salesOrderTracking.mediaItemCategories = mediaItemCategories;
            salesOrderTracking.mediaItems = mediaItems;

            await this.setState({
                salesOrderTracking: salesOrderTracking,
                hasLoaded: hasLoaded,
                asyncActive: false
            });
        }
    }

    async loadSourceDocuments() {
        let { hasLoaded, salesOrderTracking, abortController } = { ...this.state };
        const { id } = { ...this.props.match.params };

        if (!abortController.signal.aborted) {
            let [sourceDocumentsResp] = await Promise.all([
                util.fetch.get(
                    ApiRoutes.SalesOrderTracking.components.sourceDocuments(id),
                    util.fetch.format.none,
                    { signal: abortController.signal }
                )
            ]);

            if (sourceDocumentsResp.ok && !sourceDocumentsResp.url.includes('Denied')) {
                var dto = (await sourceDocumentsResp.json()).sourceDocuments;

                salesOrderTracking.salesOrderDocumentName = dto.salesOrderDocumentName;
                salesOrderTracking.salesOrderDocumentId = dto.salesOrderDocumentId;
                salesOrderTracking.workOrderDocumentName = dto.workOrderDocumentName;
                salesOrderTracking.workOrderDocumentId = dto.workOrderDocumentId;
                salesOrderTracking.mountingInstructionsDocumentName = dto.mountingInstructionsDocumentName;
                salesOrderTracking.mountingInstructionsDocumentId = dto.mountingInstructionsDocumentId;
            }

            hasLoaded.sourceDocuments = true;

            await this.setState({
                salesOrderTracking: salesOrderTracking,
                hasLoaded: hasLoaded,
                asyncActive: false
            });
        }
    }

    async loadWorkOrders() {
        console.log('loading workorder');

        let { hasLoaded, salesOrderTracking, abortController } = { ...this.state };
        const { id } = { ...this.props.match.params };

        if (!abortController.signal.aborted) {
            let [workOrdersResp] = await Promise.all([
                util.fetch.get(
                    ApiRoutes.SalesOrderTracking.components.workOrders(id),
                    util.fetch.format.none,
                    { signal: abortController.signal }
                )
            ]);

            if (workOrdersResp.ok && !workOrdersResp.url.includes('Denied')) {
                var workOrders = (await workOrdersResp.json()).workOrders;

                salesOrderTracking.workOrderCount = workOrders.length;
                salesOrderTracking.workOrders = workOrders;
            }

            hasLoaded.workOrders = true;

            await this.setState({
                salesOrderTracking: salesOrderTracking,
                hasLoaded: hasLoaded,
                asyncActive: false
            });
        }
    }

    async loadQuote() {
        console.log('loading quote');

        let { salesOrderTracking, abortController, hasLoaded, quoteLines } = { ...this.state };

        if (!abortController.signal.aborted) {
            var quote;

            let quoteResponse = await util.fetch.get(
                ApiRoutes.SalesOrderTracking.components.quote(salesOrderTracking.quoteId),
                util.fetch.format.none,
                { signal: abortController.signal }
            );

            if (!quoteResponse.ok) {
                //Handle erroneous links entered by the user
                if (quoteResponse.status === 404)
                    this.props.history.push(AppNavPaths.NotFound);
                else
                    this.props.history.push(AppNavPaths.ServerError);
                return false;
            } else {
                quote = await quoteResponse.json();

                let quoteLinesResponse = await util.fetch.get(
                    ApiRoutes.SalesOrderTracking.components.quoteLines(salesOrderTracking.id),
                    util.fetch.format.none,
                    { signal: abortController.signal }
                );

                if (!quoteLinesResponse.ok) {
                    //Handle erroneous links entered by the user
                    if (quoteLinesResponse.status === 404)
                        this.props.history.push(AppNavPaths.NotFound);
                    else
                        this.props.history.push(AppNavPaths.ServerError);
                    return false;
                } else {
                    quoteLines = await quoteLinesResponse.json();
                    hasLoaded.quote = true;
                }
            }

            await this.setState({
                quote: quote,
                quoteLines: quoteLines,
                hasLoaded: hasLoaded
            });
        }
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();
        
        if (!!isAuthenticated) {
            const { abortController } = { ...this.state };
            const { id, addByMethod, quoteId, wcid } = { ...this.props.match.params };
            const { isNestedInSlideForm } = { ...this.props }

            try {
                if (!abortController.signal.aborted) {
                    var quote;

                    if (!!id) {
                        let [srt] = await Promise.all([
                            util.fetch.js(
                                ApiRoutes.SalesOrderTracking.baseById(id),
                                { signal: abortController.signal }
                            )
                        ]);

                        await this.setState({
                            salesorderTrackingId: id,
                            salesOrderTracking: srt,
                            addByMethod: 0,
                            activeTab: '4',
                            loading: false,
                            statePopulated: true,
                            isNestedInSlideForm: isNestedInSlideForm,
                            workCenterId: wcid
                        });
                    } else {

                        if (addByMethod == 1) {
                            let { fileCreation } = { ...this.state };

                            let [customerList, prodFamList, prodCatList, quoteResponse] = await Promise.all([
                                util.fetch.js(ApiRoutes.typeAheads.apteanCustomers(), { signal: abortController.signal }),
                                util.fetch.js(ApiRoutes.typeAheads.apteanProductFamilies(), { signal: abortController.signal }),
                                util.fetch.js(ApiRoutes.typeAheads.apteanProductCategories(), { signal: abortController.signal }),
                                util.fetch.get(ApiRoutes.quote.byId(quoteId), util.fetch.format.none, { signal: abortController.signal })
                            ]);

                            if (!quoteResponse.ok) {
                                //Handle erroneous links entered by the user
                                if (quoteResponse.status === 404)
                                    this.props.history.push(AppNavPaths.NotFound);
                                else
                                    this.props.history.push(AppNavPaths.ServerError);
                                return false;
                            } else {
                                quote = await quoteResponse.json();
                                if (!!quote?.quoteDocument?.document) {
                                    fileCreation.salesOrderFileName = quote.quoteDocument.document.name;
                                    fileCreation.salesOrderFileDescription = quote.quoteDocument.document.description;
                                    fileCreation.salesOrderFile = quote.quoteDocument.document;
                                    fileCreation.salesOrderDocumentId = quote.quoteDocument.id;
                                }
                            }

                            let chosenCust = quote.quoteDealerCustomerId;
                            fileCreation.quoteId = quoteId;
                            let chosenProdFam = "DUR-A-LIFT COMPLETE";
                            fileCreation.productFamily = "DUR-A-LIFT COMPLETE";

                            //Adding by file method
                            await this.setState({
                                quote: quote,
                                customers: customerList,
                                prodFams: prodFamList,
                                prodCats: prodCatList,
                                addByMethod: addByMethod,
                                fileCreation: fileCreation,
                                chosenProdFam: chosenProdFam,
                                chosenCust: chosenCust,
                                loading: false
                            });
                        } else {
                            //Adding by sales order Serial 
                            let [trackableSalesOrders, liftTypes] = await Promise.all([
                                util.fetch.js(ApiRoutes.typeAheads.trackableSalesOrders(), { signal: abortController.signal }),
                                util.fetch.js(ApiRoutes.typeAheads.getLiftTypes(), { signal: abortController.signal })
                            ]);

                            liftTypes = liftTypes.map((lt) => { return { value: Number(lt.value), label: lt.label }; });

                            await this.setState({
                                trackableSO: trackableSalesOrders,
                                addByMethod: addByMethod,
                                loading: false,
                                liftTypes: liftTypes
                            });
                        }
                    }
                }
            } catch (err) {
                if (!(err instanceof DOMException)) {
                    console.log(err);
                } else {
                    if (err.name != "AbortError") {
                        console.log(err);
                    }
                }
            }
        }
    }

    onChange = onFieldChange;
    onSelectChanged = onReactSelectChanged;
    handleSaveError = (err) => handleFormSaveError(this, err);

    onClearErrorNotificationClicked = e => {
        e.stopPropagation();
        this.setState({ errors: {} });
    }

    onClearWarningNotificationClicked = e => {
        e.stopPropagation();
        this.setState({ warnings: {} });
    }

    onClearWorkCenterTags = async () => {
        let { salesOrderTracking } = { ...this.state };

        if (!!(salesOrderTracking.workFlowTags ?? []).length) {
            salesOrderTracking.workFlowTags.forEach((wft) => delete wft.deleted);
        }

        await this.setState({
            workflowTagsToAdd: [],
            workflowTagsToRemove: [],
            tagsSaving: false,
            salesOrderTracking: salesOrderTracking
        });
    }

    onMakeWorkflowChanges = async () => {
        this.setState({ tagsSaving: true });

        let {
            workflowTagsToAdd,
            workflowTagsToRemove,
            salesOrderTracking,
            errors,
            hasLoaded
        } = { ...this.state };

        let changesMade = false;

        if (!!(workflowTagsToAdd ?? []).length) {
            
            var add_resp = await util.fetch.put(
                ApiRoutes.SalesOrderTracking.addtags(),
                {
                    SalesOrderTrackingId: salesOrderTracking.id,
                    WorkflowTagIds: workflowTagsToAdd.map((aft) => aft.id)
                },
                util.fetch.format.none //do this so we can properly handle the error
            );

            if (!!add_resp.ok) {
                toasty.success("Workflow Tags Added");
                changesMade = true;
            }
            else {
                let resp = await add_resp.json()
                errors.AddWorkflowTags = resp.message;
                toasty.error("There was an error adding the workflow tags. See below");
            }
        }

        if (!!(workflowTagsToRemove ?? []).length) {
            
            var rem_resp = await util.fetch.put(
                ApiRoutes.SalesOrderTracking.removetags(),
                {
                    SalesOrderTrackingId: salesOrderTracking.id,
                    WorkflowTagIds: workflowTagsToRemove.map((rft) => rft.workFlowStatusTag_Id)
                },
                util.fetch.format.none //do this so we can properly handle the error
            );

            if (!!rem_resp.ok) {
                toasty.success("Workflow Tags Removed");
                changesMade = true;
            }
            else {
                let resp = await add_resp.json()
                errors.RemoveWorkflowTags = resp.message;
                toasty.error("There was an error removing the workflow tags. See below");
            }
        }

        if (!!errors.AddWorkflowTags || !!errors.RemoveWorkflowTags) {
            this.setState({ errors: errors });
        }

        hasLoaded.workflowTags = false;

        if (changesMade) {           
            this.setState({ tagsSaving: false, hasLoaded: hasLoaded });
            await this.onClearWorkCenterTags();
            await this.loadWorkflowTags();
        }

    }

    onSubmit = async () => {
        await this.setState({ saving: true });
        this.setState((state) => { return { errors: {} }; });

        let {
            addByMethod, chosenSO, chosenCust, chosenProdFam, chosenProdCat, fileCreation,
            errors, warnings, chosenLiftType
        } = { ...this.state };

        //Adding by file
        if (addByMethod == 1) {
            try {

                let valid = true;

                if (!chosenCust) {
                    valid = false;
                    errors.customer = "Must choose a customer";
                }

                if (!chosenProdFam) {
                    valid = false;
                    errors.productFamily = "Must choose a product family";
                }

                if (!chosenProdCat) {
                    valid = false;
                    errors.productCategory = "Must choose a product category";
                }

                if (!fileCreation.mountingInstructionsFile) {
                    valid = false;
                    errors.mountingInstructionsFile = "Must choose a mounting instruction file";
                }

                if (!fileCreation.workOrderFile) {
                    valid = false;
                    errors.workOrderFile = "Must choose a work order file";
                }

                if (!fileCreation.orderDate) {
                    valid = false;
                    errors.orderDate = "Must provide an order date";
                }

                if (!fileCreation.promiseDate) {
                    valid = false;
                    errors.promiseDate = "Must provide a promise date";
                }

                if (!fileCreation.requiredDate) {
                    valid = false;
                    errors.requiredDate = "Must provide a required date";
                }

                if (!valid) {
                    this.setState({ errors: errors });
                    toasty.warning("Please review the form for validation errors");
                } else {

                    await this.upload(
                        ApiRoutes.SalesOrderTracking.upload(),
                        fileCreation.workOrderFile.file,
                        fileCreation.workOrderFileName,
                        (fileCreation.workOrderFileDescription ?? ""),
                        'wo'
                    );

                    await this.upload(
                        ApiRoutes.SalesOrderTracking.upload(),
                        fileCreation.mountingInstructionsFile.file,
                        fileCreation.mountingInstructionsFileName,
                        (fileCreation.mountingInstructionsFileDescription ?? ""),
                        'mi'
                    )

                    var cff_resp = await util.fetch.post(
                        ApiRoutes.SalesOrderTracking.createFromFiles(),
                        {
                            QuoteId: fileCreation.quoteId,
                            WorkOrderFile: fileCreation.workOrderDocumentId,
                            MountingInstructionsFile: fileCreation.mountingInstructionsDocumentId ?? 0,
                            CustomerNumber: chosenCust,
                            ProductFamily: chosenProdFam,
                            ProductCategory: chosenProdCat,
                            OrderDate: fileCreation.orderDate,
                            PromiseDate: fileCreation.promiseDate,
                            RequiredDate: fileCreation.requiredDate
                        },
                        util.fetch.format.none //do this so we can properly handle the error
                    );
                    
                    if (!!cff_resp.ok) {
                        let sotResp = await cff_resp.json();
                        if (!!(sotResp.message?.length ?? 0)) {
                            warnings["Server Warnings"] = sotResp.message;
                            toasty.warning("Sales Order Tracking Saved with warnings.");
                        } else {
                            toasty.success("Sales Order Tracking Saved");
                        }
                        this.props.history.push(`${AppNavPaths.SalesOrderTracking}/${sotResp.salesOrderTracking.id}`)
                    }
                    else {
                        let resp = await cff_resp.json()
                        errors["Create Sales Order"] = resp.message;
                        this.setState({ errors: errors });
                        //error
                        toasty.error("There was an error creating the sales order. See below");
                    }
                }
            } catch (ex) {
                errors["Create Sales Order"] = ex.toString();
                this.setState({ errors: errors });
                toasty.error("There was an issue creating the tracking. Please try again or contact support for assistance.");
            } finally {
                this.setState({ saving: false });
            }
        } else {
            //Adding by sales order

            try {
                var cfs_resp = await util.fetch.post(ApiRoutes.SalesOrderTracking.createFromItem(), {
                    chosenSO: chosenSO,
                    chosenLiftType: chosenLiftType
                }, util.fetch.format.none);

                if (cfs_resp.redirected) {
                    window.location.href = cfs_resp.url;
                } else if (!!cfs_resp.ok) {
                    var serviceResponse = await cfs_resp.json();
                    if (serviceResponse.result === 'SUCCESS') {
                        toasty.success("Sales Order Tracking Saved");

                        this.props.history.push(`${AppNavPaths.SalesOrderTracking}/${serviceResponse.data.id}`);
                    } else {
                        let resp = await cfs_resp.json()
                        errors["Create Sales Order"] = resp.message;
                        this.setState({ errors: errors });
                        //error
                        toasty.error("There was an error creating the sales order. See below");
                    }
                }
            } catch(ex) {
                errors.createSalesOrder = ex.toString();
                this.setState({ errors: errors });
                toasty.error("There was an issue creating the tracking. Please try again or contact support for assistance.");
            } finally {
                this.setState({ saving: false });
            }
        }
    }

    upload = (url, file, name, description, type) => {

        return new Promise((resolve) => {

            let docType = 0;

            switch (type) {
                case 'wo':
                    docType = DocumentTypes.WorkOrder;
                    break;
                case 'mi':
                    docType = DocumentTypes.MountingInstructions;
                    break;
                default:
                    docType = DocumentTypes.WorkOrder;
                    break;
            }

            var formData = new FormData();
            formData.append('file', file, file.path);
            formData.append("documentName", name);
            formData.append("documentDescription", description);
            formData.append("documentTypeId", docType);

            var xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);

            let xsrfToken = util.getCookie('X-DAL-AF');
            xhr.setRequestHeader('X-CSRF-TOKEN', xsrfToken);

            xhr.upload.onprogress = (e) => this.onProgressUpdated(e, type);
            xhr.responseType = 'json';
            xhr.onload = () => {
                let { fileCreation } = { ...this.state };
                let docId = xhr.response;

                if (type === 'wo') {
                    fileCreation.workOrderDocumentId = docId;
                }

                if (type === 'mi') {
                    fileCreation.mountingInstructionsDocumentId = docId;
                }

                this.setState({ fileCreation: fileCreation });
                resolve();
            };
            xhr.send(formData);
        });
    }

    remove = (designator) => {
        let { fileCreation } = { ...this.state };

        switch (designator) {
            case 'workOrder':
                fileCreation.workOrderFile = null;
                break;
            case 'mountInst':
                fileCreation.mountingInstructionsFile = null;
                break;
            default:
                break;
        }
        this.setState({ fileCreation: fileCreation });
    }

    onProgressUpdated = (e, type) => {
        if (e.lengthComputable) {
            var percentage = Math.round((e.loaded / e.total) * 100);

            let { fileCreation } = { ...this.state };

            if (type === 'wo') {
                fileCreation.workOrderFile.progress = percentage;
            }

            if (type === 'mi') {
                fileCreation.mountingInstructionsFile.progress = percentage;
            }

            this.setState({
                fileCreation: fileCreation
            });
        }
    }

    onLiftOptionCheckboxChange = async (e, liftOptionId) => {
        let { salesOrderTracking } = this.state;

        let liftOption = salesOrderTracking.liftOptions.find(x => x.id == liftOptionId);
        liftOption.isCompleted = e.target.checked;
        liftOption.modifiedOn = moment.utc();

        this.setState({ salesOrderTracking: salesOrderTracking });
        await util.fetch.put(ApiRoutes.SalesOrderTracking.liftOptionPut(), liftOption, util.fetch.format.none);
    }

    onMountingInstructionCheckboxChange = async (e, mountingInstructionId) => {
        let { salesOrderTracking } = this.state;

        let mountingInstruction = salesOrderTracking.mountingInstructions.find(x => x.id == mountingInstructionId);
        mountingInstruction.isCompleted = e.target.checked;
        mountingInstruction.modifiedOn = moment.utc();

        this.setState({ salesOrderTracking: salesOrderTracking });
        await util.fetch.put(ApiRoutes.SalesOrderTracking.mountingInstructionPut(), mountingInstruction, util.fetch.format.none);
    }

    onDownloadDocument = async (id) => {
        var [getResponse] = await Promise.all([
            util.fetch.get(ApiRoutes.SalesOrderTracking.document(id), util.fetch.format.file)
        ]);

        if (!!getResponse?.error ?? false) {
            //handle erroneous links entered by the user
            let serviceresponse = {
                title: 'server error',
                errors: {
                    exception: [
                        getResponse.error
                    ]
                }
            }
            this.handleSaveError(serviceresponse);
            return false;
        } else {
            const a = document.createElement("a");
            a.href = getResponse.url;
            a.target = "_blank";
            a.download = getResponse.fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    }

    onShowDrawing = async (id) => {

        var [getResponse] = await Promise.all([
            util.fetch.get(ApiRoutes.SalesOrderTracking.drawings(id), util.fetch.format.file)
        ]);

        if (!!getResponse?.error ?? false) {
            //handle erroneous links entered by the user
            let serviceresponse = {
                title: 'server error',
                errors: {
                    exception: [
                        getResponse.error
                    ]
                }
            }
            this.handleSaveError(serviceresponse);
            return false;
        } else {
            window.open(getResponse.url);
        }
    }

    onDownloadDrawing = async (id) => {

        var [getResponse] = await Promise.all([
            util.fetch.get(ApiRoutes.SalesOrderTracking.drawings(id), util.fetch.format.file)
        ]);

        if (!!getResponse?.error ?? false) {
            //handle erroneous links entered by the user
            let serviceresponse = {
                title: 'server error',
                errors: {
                    exception: [
                        getResponse.error
                    ]
                }
            }
            this.handleSaveError(serviceresponse);
            return false;
        } else {
            const a = document.createElement("a");
            a.href = getResponse.url;
            a.target = "_blank";
            a.download = getResponse.fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    }

    onShowMedia = async (id) => {

        var [getResponse] = await Promise.all([
            util.fetch.get(ApiRoutes.SalesOrderTracking.media(id), util.fetch.format.file)
        ]);

        if (!!getResponse?.error ?? false) {
            //handle erroneous links entered by the user
            let serviceresponse = {
                title: 'server error',
                errors: {
                    exception: [
                        getResponse.error
                    ]
                }
            }
            this.handleSaveError(serviceresponse);
            return false;
        } else {
            //const a = document.createElement("a");
            //a.href = getResponse.url;
            //a.target = "_blank";
            //a.download = getResponse.fileName;
            //document.body.appendChild(a);
            //a.click();
            //a.remove();
            window.open(getResponse.url);
        }
    }

    onDownloadMedia = async (id) => {

        var [getResponse] = await Promise.all([
            util.fetch.get(ApiRoutes.SalesOrderTracking.media(id), util.fetch.format.file)
        ]);

        if (!!getResponse?.error ?? false) {
            //handle erroneous links entered by the user
            let serviceresponse = {
                title: 'server error',
                errors: {
                    exception: [
                        getResponse.error
                    ]
                }
            }
            this.handleSaveError(serviceresponse);
            return false;
        } else {
            const a = document.createElement("a");
            a.href = getResponse.url;
            a.target = "_blank";
            a.download = getResponse.fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    }

    onEngineerChangeOrder = () => {
        let { salesorderTrackingId, isNestedInSlideForm, workCenterId } = { ...this.state };

        if (isNestedInSlideForm) {
            const { routingHistory } = { ...this.props }

            if (workCenterId) {
                routingHistory.push(`${AppNavPaths.SalesOrderTrackingEngineerChangeOrder}/${salesorderTrackingId}/${workCenterId}`)
            } else {
                routingHistory.push(`${AppNavPaths.SalesOrderTrackingEngineerChangeOrder}/${salesorderTrackingId}`)
            }
        } else {
            this.props.history.push(`${AppNavPaths.SalesOrderTrackingEngineerChangeOrder}/${salesorderTrackingId}`)
        }
        
    }

    onNavigateToWorkflow = (workCenterId) => {
        let { salesorderTrackingId, isNestedInSlideForm } = { ...this.state };

        if (isNestedInSlideForm) {
            const { routingHistory } = { ...this.props }

            routingHistory.push(`${AppNavPaths.WorkCenterWorkFlow}/${workCenterId}/${salesorderTrackingId}`);
        } else {
            this.props.history.push(`${AppNavPaths.WorkCenterWorkFlow}/${workCenterId}/${salesorderTrackingId}`);
        }
    }

    onNavigateToWorkflowForm = (workCenterId, sotId, formId) => {
        let { isNestedInSlideForm } = { ...this.state };

        if (isNestedInSlideForm) {
            const { routingHistory } = { ...this.props }

            routingHistory.push(`${AppNavPaths.SalesOrderTrackingSurvey}/${workCenterId}/${sotId}/${formId}`);
        } else {
            this.props.history.push(`${AppNavPaths.SalesOrderTrackingSurvey}/${workCenterId}/${sotId}/${formId}`);           
        }
    }

    //Survey section
    async createSurveySearchGrid() {
        let { hasLoaded, salesorderTrackingId, abortController } = { ...this.state };
        const { currentUser } = await getUserProfile();
        const userRoles = currentUser.roles.map(x => x.name);
        const userIsAdmin = userRoles.includes('Administrator');

        let gridOptions = createGridOptions(this);

        let [statusList] = await Promise.all([
            util.fetch.js(
                ApiRoutes.typeAheads.surveyCompletionStatuses(),
                { signal: abortController.signal }
            )
        ]);

        let statusFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Status',
            options: [...new Set([
                ...statusList.map((s) => { return { label: s.label, value: s.label } }),
                { label: 'All', value: 'All' }])
            ],
            optionsLabel: 'label',
            optionsValue: 'value',
            initialFilterValue: 'Open'
        }

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
            iconRenderer: ConditionalPureIconCellRenderer,
            indexCellRenderer: IndexCellRendererWithEdit
        };

        gridOptions.columnDefs = [
            {
                headerName: "",
                valueGetter: "node.id",
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                minWidth: 35,
                cellRenderer: this.indexCellRenderer
            },
            {
                colId: 'Id',
                minWidth: 75,
                maxWidth: 75,
                headerName: '',
                sortable: false,
                cellStyle: { color: "rgba(0,0,0,0)" },
                cellRenderer: (params) => {
                    return (
                        <SmallButton
                            type="button"
                            disabled={!!this.props.formIsOpen}
                            onClick={() => {
                                this.onNavigateToWorkflowForm(
                                    params.data.workCenterId,
                                    params.data.salesOrderTrackingId,
                                    params.data.id
                                );
                            }}
                        >
                            <i className={`fa ${userIsAdmin ? 'fa-edit' : 'fa-eye'} fa-md mr-2`} />
                        </SmallButton>
                    );
                }
            },
            {
                colId: 'WorkCenterSurvey.WorkCenter.Name',
                headerName: 'Work Center',
                field: 'workCenterName',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'WorkCenterSurvey.Survey.Name',
                headerName: 'Form Name',
                field: 'surveyName',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'Status.Name',
                headerName: 'Form Status',
                field: 'status',
                sortable: true,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: statusFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: statusFilterParams
            },
            {
                colId: 'OpenIssueCount',
                headerName: 'Open Issues',
                field: 'openIssueCount',
                sortable: true
            },
            {
                colId: 'percentageComplete',
                headerName: 'Form Progress',
                sortable: false,
                maxWidth: 140,
                minWidth: 140,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                },
                cellRenderer: (params) => {
                    if (params.data) {
                        let prog = params.data.percentageComplete;
                        let color = "danger";
                        if (prog > 50) { color = "warning" }
                        if (prog == 100) { color = "success" }
                        return (
                            <>
                                {(prog != -1) &&
                                    <Progress
                                        className="my-2"
                                        color={color}
                                        value={prog}
                                        style={{ position: 'relative' }}
                                    >
                                        <small style={{ color: 'black' }} className={"justify-content-center d-flex position-absolute w-100"}>{`${prog}%`}</small>
                                    </Progress>
                                }
                                {(prog == -1) &&
                                    <span>N/A</span>
                                }
                            </>
                        );
                    }

                    return null;
                }
            }
        ];

        hasLoaded.workCenterForms = true;

        gridOptions.customParametersFunction = () => ({ SalesOrderTrackingId: parseInt(salesorderTrackingId) });
        gridOptions.useLoading = true;
        gridOptions.loadingTemplate = '<span className="ag-overlay-loading-center "><i class="faSaveSpinner fa fa-md fa-circle-notch"></i> Loading ...</span>';

        let ds = createDataSource(ApiRoutes.SalesOrderTracking.components.searchSurveys(), gridOptions);

        this.setState(state => {
            return {
                hasLoaded: hasLoaded,
                gridOptions: gridOptions,
                dataSource: ds
            }
        });

    }

    render() {
        let {
            salesorderTrackingId,
            addByMethod,
            trackableSO,
            chosenSO,
            salesOrderTracking,
            customers,
            chosenCust,
            prodFams,
            chosenProdFam,
            prodCats,
            chosenProdCat,
            fileCreation,
            formValidated,
            validationMessage,
            loading,
            saving,
            errors,
            warnings,
            sequenceData,
            liftTypes,
            chosenLiftType,
            isNestedInSlideForm,
            quote,
            quoteLines,
            workFlowStatusTags,
            workflowTagsToAdd,
            workflowTagsToRemove,
            tagsSaving,
            hasLoaded,
            rowData,
            gridOptions,
            asyncActive
        } = this.state;

        const acceptProp = {
            'application/vnd.ms-excel': ['.xls','.xlsx'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx']
        };

        return (
            <Fragment>
                <AppPageForm
                    formId={"salesOrderTrackingForm"}
                    formHeadingIcon="fa-chart-line"
                    formHeading='Order Tracking'
                    collapseFormHeading={isNestedInSlideForm}
                    formName={"workCenterForm"}
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                    isValidated={formValidated}
                    saving={this.state.saving}
                    errors={errors}
                    warnings={warnings}
                    loading={loading}
                    onClearErrors={this.onClearErrorNotificationClicked}
                    onClearWarnings={this.onClearWarningNotificationClicked}
                    validationMessage={validationMessage}
                    showThisValidation={false}
                    ValidationBottom={true}
                >
                {!!(salesorderTrackingId)
                    /* display*/
                    && <>
                        <Modal isOpen={this.state.showSequenceModal} toggle={this.toggleSequence} >
                            <ModalHeader toggle={this.toggleSequence} charCode="Y">Work Center Sequence</ModalHeader>
                            <ModalBody>
                                {(!!sequenceData && !!sequenceData.syncWorkCenterIcon)&& <FontAwesomeIcon size='sm' icon={sequenceData.syncWorkCenterIcon} />}
                                {' '}
                                {sequenceData.syncWorkCenterName !== 'None' ? sequenceData.syncWorkCenterName + ((sequenceData.apteanWorkCenterName ?? "").length ? "(" + sequenceData.apteanWorkCenterName + ")" : "") : sequenceData.apteanWorkCenterName}
                                <Row>
                                    <Col sm={12} md={6} lg={6}>
                                    <Card style={{ textAlign: "center"}}>
                                        <CardBody>
                                            <CardTitle tag="h5">Predecessors</CardTitle>
                                            <div className="card-text">
                                                <Table id='liftOptionsTable' striped>
                                                    <tbody>
                                                        {!!(sequenceData.predecessors ?? []).length &&
                                                            (sequenceData.predecessors ?? []).map((pr) =>
                                                                <tr key={uuidv4()} >
                                                                    <td>{pr.name}</td>
                                                                </tr>
                                                            )
                                                        }
                                                        {!!!(sequenceData.predecessors ?? []).length &&
                                                            <tr>
                                                                <td>No Predecessors</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    </Col>
                                    <Col sm={12} md={6} lg={6}>
                                    <Card style={{ textAlign: "center" }}>
                                        <CardBody>
                                            <CardTitle tag="h5">Successors</CardTitle>
                                            <div className="card-text">
                                                <Table id='liftOptionsTable' striped>
                                                    <tbody>
                                                        {!!(sequenceData.successors ?? []).length &&
                                                            (sequenceData.successors ?? []).map((sc) =>
                                                                <tr key={uuidv4()} >
                                                                    <td>{sc.name}</td>
                                                                </tr>
                                                            )
                                                        }
                                                        {!!!(sequenceData.successors ?? []).length &&
                                                            <tr>
                                                                <td>No Successors</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={this.toggleSequence}>Close</Button>
                            </ModalFooter>
                        </Modal>
                        <div>
                            {!isNestedInSlideForm && 
                                <Row>
                                    <Col sm={8} md={10} lg={10} >
                                        <h4>Sales Order Tracking Details</h4>
                                    </Col>
                                    <Col sm={4} md={2} lg={2} style={{textAlign: "right"}} >
                                        <Button type="button"
                                            className="mr-3"
                                            size="sm"
                                            disabled={!!saving}
                                            onClick={() => this.props.history.push(`${AppNavPaths.SalesOrderTracking}`)}>
                                            <FontAwesomeIcon size="lg" icon={"fa-times"} className="mr-2" />
                                            {"Close"}
                                        </Button>
                                    </Col>
                                </Row>
                            }
                        </div>
                        <div className={classnames("sales-order-tracking-details", {"mt-2": isNestedInSlideForm})}>
                            <Row style={{ marginRight: 'auto', marginLeft: 'auto' }} >
                                <Col sm={6} md={2} lg={2}>
                                    <FormLabel
                                        htmlFor="customer"
                                        text="Dealer / Customer:"
                                    />
                                </Col>
                                <Col sm={6} md={4} lg={4}>
                                    <span id="customer"
                                        name="customer"
                                        className="form-control-sm form-control-plaintext"
                                    >{salesOrderTracking.customer}</span>
                                </Col>
                                <Col sm={6} md={2} lg={2}>
                                    <FormLabel
                                        htmlFor="requiredDate"
                                        text="Required Date:"
                                    />
                                </Col>
                                <Col sm={6} md={4} lg={4}>
                                    <span id="requiredDate"
                                        name="requiredDate"
                                        className="form-control-sm form-control-plaintext"
                                    >{salesOrderTracking.requiredDate}</span>
                                </Col>
                            </Row>
                            <Row style={{ marginRight: 'auto', marginLeft: 'auto' }} >
                                <Col sm={6} md={2} lg={2}>
                                    <FormLabel
                                        htmlFor="customerAddress"
                                        text="Address:"
                                    />
                                </Col>
                                <Col sm={6} md={4} lg={4}>
                                    <span id="customerAddress"
                                        name="customerAddress"
                                        className="form-control-sm form-control-plaintext"
                                    >{salesOrderTracking.customerAddress}</span>
                                </Col>
                                <Col sm={6} md={2} lg={2}>
                                    <FormLabel
                                        htmlFor="promisedDate"
                                        text="Promised Date:"
                                    />
                                </Col>
                                <Col sm={6} md={4} lg={4}>
                                    <span id="promisedDate"
                                        name="promisedDate"
                                        className="form-control-sm form-control-plaintext"
                                    >{salesOrderTracking.promisedDate}</span>
                                </Col>
                            </Row>
                            <Row style={{ marginRight: 'auto', marginLeft: 'auto' }} >
                                <Col sm={6} md={2} lg={2}>
                                    <FormLabel
                                        htmlFor="customerCityStateZip"
                                        text=" "
                                    />
                                </Col>
                                <Col sm={6} md={4} lg={4}>
                                    <span id="customerCityStateZip"
                                        name="customerCityStateZip"
                                        className="form-control-sm form-control-plaintext"
                                    >{salesOrderTracking.customerCityStateZip}</span>
                                </Col>
                                <Col sm={6} md={2} lg={2}>
                                    <FormLabel
                                        htmlFor="orderDate"
                                        text="Order Date:"
                                    />
                                </Col>
                                <Col sm={6} md={4} lg={4}>
                                    <span id="orderDate"
                                        name="orderDate"
                                        className="form-control-sm form-control-plaintext"
                                    >{salesOrderTracking.orderDate}</span>
                                </Col>
                            </Row>
                            <Row style={{ marginRight: 'auto', marginLeft: 'auto' }} >
                                <Col sm={6} md={2} lg={2}>
                                    <FormLabel
                                        htmlFor="serialNumber"
                                        text="S/N:"
                                    />
                                </Col>
                                <Col sm={6} md={4} lg={4}>
                                    <span id="serialNumber"
                                        name="serialNumber"
                                        className="form-control-sm form-control-plaintext"
                                    >{salesOrderTracking.serialNumber}</span>
                                </Col>
                                <Col sm={6} md={2} lg={2}>
                                    <FormLabel
                                        htmlFor="salesOrderNumber"
                                        text="Sales Order#:"
                                    />
                                </Col>
                                <Col sm={6} md={4} lg={4}>
                                    <span id="salesOrderNumber"
                                        name="salesOrderNumber"
                                        className="form-control-sm form-control-plaintext"
                                    >{salesOrderTracking.salesOrderNumber}</span>
                                </Col>
                            </Row>
                            <Row style={{ marginRight: 'auto', marginLeft: 'auto' }} >
                                <Col sm={6} md={2} lg={2}>
                                    <FormLabel
                                        htmlFor="itemName"
                                        text="Model #:"
                                    />
                                </Col>
                                <Col sm={6} md={4} lg={4}>
                                    <span id="itemName"
                                        name="itemName"
                                        className="form-control-sm form-control-plaintext"
                                        >{salesOrderTracking.itemName}</span>
                                </Col>
                                <Col sm={6} md={2} lg={2}>
                                    {salesOrderTracking.lastEngineeringChangeOrder && 
                                        <FormLabel
                                            htmlFor="lastEngineeringChangeOrder"
                                            text="Engineering C/O:"
                                        />
                                    }
                                </Col>
                                <Col sm={6} md={4} lg={4}>
                                    {salesOrderTracking.lastEngineeringChangeOrder &&
                                        <span className="form-control-sm">{salesOrderTracking.lastEngineeringChangeOrder}</span>
                                    }
                                    <Can I="create" a="engineer_change_order">
                                        <Button type="button"
                                            style={{fontSize: "smaller", marginLeft: "1em"} }
                                            color="secondary"
                                            disabled={!!saving || !!asyncActive}
                                            onClick={this.onEngineerChangeOrder}>
                                            <FontAwesomeIcon size="sm" icon={"fa-edit"} className={"mr-2"} />
                                            {'Engineer Change Order'}
                                        </Button>
                                    </Can>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '4' })}
                                            onClick={async (event) => {
                                                event.persist();
                                                await this.toggle('4');
                                            }}
                                        >Work Orders</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '8' })}
                                            onClick={async (event) => {
                                                event.persist();
                                                await this.toggle('8');
                                            }}
                                        >Quote</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '1' })}
                                            onClick={async (event) => {
                                                event.persist();
                                                await this.toggle('1');
                                            }}
                                        >Lift Options</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '2' })}
                                            onClick={async (event) => {
                                                event.persist();
                                                await this.toggle('2');
                                            }}
                                        >Mounting Instructions</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '3' })}
                                            onClick={async (event) => {
                                                event.persist();
                                                await this.toggle('3');
                                            }}
                                        >Drawings</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '5' })}
                                            onClick={async (event) => {
                                                event.persist();
                                                await this.toggle('5');
                                            }}
                                        >Source Documents</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '6' })}
                                            onClick={async (event) => {
                                                event.persist();
                                                await this.toggle('6');
                                            }}
                                        >Work Center Forms</NavLink>
                                    </NavItem>
                                    <Can do="all" on="administrator">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === '7' })}
                                                    onClick={async (event) => {
                                                        event.persist();
                                                        await this.toggle('7');
                                                    }}
                                            >Workflow Tags</NavLink>
                                        </NavItem>
                                    </Can>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab} style={{paddingTop: '1em'}} >
                                    <TabPane tabId="1">
                                        {!hasLoaded.liftOptions &&
                                            <h1 style={{ textAlign: 'center' }}><i className="fa fa-spin fa-circle-notch text-danger"></i></h1>
                                        }
                                        {hasLoaded.liftOptions &&
                                            <Row>
                                                <Col sm={12} md={12} lg={12} >
                                                    <Table id='liftOptionsTable' striped>
                                                        <tbody>
                                                            {!!(salesOrderTracking.liftOptions ?? []).length &&
                                                                (salesOrderTracking.liftOptions ?? []).map((lo) =>
                                                                    <tr key={lo.id} >
                                                                        <td>
                                                                            <FormGroup check inline >
                                                                                <Input type="checkbox" className="mr-3" checked={!!lo.isCompleted} data-id={lo.id} onChange={(e) => this.onLiftOptionCheckboxChange(e, lo.id)} />
                                                                                {lo.option}
                                                                                {!!lo.isCompleted &&
                                                                                    <Tooltip title={`Completed by ${lo.modifiedByUser} ${moment.utc(lo.modifiedOn).local().format('MM-DD-YYYY HH:mm:ss')}`} placement="top" className="ml-2" arrow>
                                                                                        <small className="text-muted font-italic">{lo.modifiedByUser}</small>
                                                                                    </Tooltip>}
                                                                            </FormGroup>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            {!!!(salesOrderTracking.liftOptions ?? []).length &&
                                                                <tr>
                                                                    <td>No Lift Options</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        }
                                    </TabPane>
                                    <TabPane tabId="2">
                                        {!hasLoaded.mountingInstructions &&
                                            <h1 style={{ textAlign: 'center' }}><i className="fa fa-spin fa-circle-notch text-danger"></i></h1>
                                        }
                                        {hasLoaded.mountingInstructions &&
                                            <Row>
                                                <Col sm={12} >
                                                    <Row>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel
                                                                htmlFor="makeAndModel"
                                                                text="Make And Model:"
                                                            />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="makeAndModel"
                                                                name="makeAndModel"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{salesOrderTracking.chassisInfo.makeAndModel}</span>
                                                        </Col>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel
                                                                htmlFor="year"
                                                                text="Year:"
                                                            />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="year"
                                                                name="year"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{salesOrderTracking.chassisInfo.year}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel
                                                                htmlFor="engine"
                                                                text="Engine:"
                                                            />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="engine"
                                                                name="engine"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{salesOrderTracking.chassisInfo.engine}</span>
                                                        </Col>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel
                                                                htmlFor="transmission"
                                                                text="Transmission:"
                                                            />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="transmission"
                                                                name="transmission"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{salesOrderTracking.chassisInfo.transmission}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel
                                                                htmlFor="bodyBrand"
                                                                text="Body Brand:"
                                                            />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="bodyBrand"
                                                                name="bodyBrand"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{salesOrderTracking.chassisInfo.bodyBrand}</span>
                                                        </Col>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel
                                                                htmlFor="bodyType"
                                                                text="Body Type:"
                                                            />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="bodyType"
                                                                name="bodyType"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{salesOrderTracking.chassisInfo.bodyType}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel
                                                                htmlFor="cabToAxel"
                                                                text="Cab To Axel:"
                                                            />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="cabToAxel"
                                                                name="cabToAxel"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{salesOrderTracking.chassisInfo.cabToAxel}</span>
                                                        </Col>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel
                                                                htmlFor="wheelBase"
                                                                text="Wheel Base:"
                                                            />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="wheelBase"
                                                                name="wheelBase"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{salesOrderTracking.chassisInfo.wheelBase}</span>
                                                        </Col>
                                                    </Row>
                                                    <Table striped style={{ borderTop: "4px solid" }}>
                                                        <colgroup>
                                                            <col style={{ width: '2%' }}></col>
                                                            <col style={{ width: '8%' }}></col>
                                                            <col style={{ width: '20%' }}></col>
                                                            <col style={{ width: '30%' }}></col>
                                                            <col style={{ width: '40%' }}></col>
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={5} style={{ textAlign: "center" }} >SERIAL NUMBER BILL OF MATERIAL ITEMS</th>
                                                            </tr>
                                                            <tr>
                                                                <th></th>
                                                                <th>QTY</th>
                                                                <th>Part #</th>
                                                                <th>Description</th>
                                                                <th>Instructions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!!(salesOrderTracking.mountingInstructions?.filter(
                                                                (mi) => mi.detailTypeId == 1)
                                                                ?? []
                                                            ).length &&
                                                                (salesOrderTracking.mountingInstructions?.filter(
                                                                    (mi) => mi.detailTypeId == 1) ?? []).map((mi) =>
                                                                    <tr key={mi.id} >
                                                                        <td>
                                                                            <FormGroup check inline >
                                                                                <Input type="checkbox" className='ml-2' checked={!!mi.isCompleted} onChange={(e) => this.onMountingInstructionCheckboxChange(e, mi.id)} />
                                                                                {!!mi.isCompleted &&
                                                                                    <Tooltip title={`Completed by ${mi.modifiedByUser} ${moment.utc(mi.modifiedOn).local().format('MM-DD-YYYY HH:mm:ss')}`} placement="top" className="ml-2" arrow>
                                                                                        <small className="text-muted font-italic">{mi.modifiedByUser}</small>
                                                                                    </Tooltip>}
                                                                            </FormGroup>
                                                                        </td>
                                                                        <td>{mi.quantity}</td>
                                                                        <td>{mi.partNumber}</td>
                                                                        <td>{mi.description}</td>
                                                                        <td>{mi.instructions.join(', ')}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                            {!!!(salesOrderTracking.mountingInstructions?.filter(
                                                                (mi) => mi.detailTypeId == 1)
                                                                ?? []
                                                            ).length &&
                                                                <tr>
                                                                    <td colSpan={5} style={{ textAlign: "center" }} >No BOM Instructions</td>
                                                                </tr>
                                                            }
                                                            <tr><td colSpan={5}></td></tr>
                                                            <tr><th colSpan={5} style={{ textAlign: "center" }}>ELECTRICAL</th></tr>
                                                            {!!(salesOrderTracking.mountingInstructions?.filter(
                                                                (mi) => mi.detailTypeId == 3)
                                                                ?? []
                                                            ).length &&
                                                                (salesOrderTracking.mountingInstructions?.filter(
                                                                    (mi) => mi.detailTypeId == 3) ?? []).map((mi) =>
                                                                    <tr key={mi.id} >
                                                                        <td>
                                                                            <FormGroup check inline >
                                                                                <Input type="checkbox" className='ml-2' checked={!!mi.isCompleted} onChange={(e) => this.onMountingInstructionCheckboxChange(e, mi.id)} />
                                                                                {!!mi.isCompleted &&
                                                                                    <Tooltip title={`Completed by ${mi.modifiedByUser} ${moment.utc(mi.modifiedOn).local().format('MM-DD-YYYY HH:mm:ss')}`} placement="top" className="ml-2" arrow>
                                                                                        <small className="text-muted font-italic">{mi.modifiedByUser}</small>
                                                                                    </Tooltip>}
                                                                            </FormGroup>
                                                                        </td>
                                                                        <td>{mi.quantity}</td>
                                                                        <td>{mi.partNumber}</td>
                                                                        <td>{mi.description}</td>
                                                                        <td>{mi.instructions.join(', ')}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                            {!!!(salesOrderTracking.mountingInstructions?.filter(
                                                                (mi) => mi.detailTypeId == 3)
                                                                ?? []
                                                            ).length &&
                                                                <tr>
                                                                    <td colSpan={5} style={{ textAlign: "center" }} >No Electrical BOM Instructions</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                    <Table striped style={{borderTop: "4px solid"}}>
                                                        <colgroup>
                                                            <col style={{ width: '2%' }}></col>
                                                            <col style={{ width: '8%' }}></col>
                                                            <col style={{ width: '20%' }}></col>
                                                            <col style={{ width: '30%' }}></col>
                                                            <col style={{ width: '40%' }}></col>
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={5} style={{textAlign: "center"}} >SALES ORDER ITEMS</th>
                                                            </tr>
                                                            <tr>
                                                                <th></th>
                                                                <th>QTY</th>
                                                                <th>Part #</th>
                                                                <th>Description</th>
                                                                <th>Instructions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!!(salesOrderTracking.mountingInstructions?.filter(
                                                                (mi) => mi.detailTypeId == 2)
                                                                ?? []
                                                            ).length &&
                                                                (salesOrderTracking.mountingInstructions?.filter(
                                                                    (mi) => mi.detailTypeId == 2) ?? []).map((mi) =>
                                                                        <tr key={mi.id} >
                                                                            <td>
                                                                                <FormGroup check inline >
                                                                                    <Input type="checkbox" className='ml-2' checked={!!mi.isCompleted} onChange={(e) => this.onMountingInstructionCheckboxChange(e, mi.id)} />
                                                                                    {!!mi.isCompleted &&
                                                                                        <Tooltip title={`Completed by ${mi.modifiedByUser} ${moment.utc(mi.modifiedOn).local().format('MM-DD-YYYY HH:mm:ss')}`} placement="top" className="ml-2" arrow>
                                                                                            <small className="text-muted font-italic">{mi.modifiedByUser}</small>
                                                                                        </Tooltip>}
                                                                                </FormGroup>
                                                                            </td>
                                                                            <td>{mi.quantity}</td>
                                                                            <td>{mi.partNumber}</td>
                                                                            <td>{mi.description}</td>
                                                                            <td>{mi.instructions.join(', ')}</td>
                                                                        </tr>
                                                                    )
                                                            }
                                                            {!!!(salesOrderTracking.mountingInstructions?.filter(
                                                                (mi) => mi.detailTypeId == 2)
                                                                ?? []
                                                            ).length &&
                                                                <tr>
                                                                    <td colSpan={5} style={{ textAlign: "center" }} >No Sales Order Instructions</td>
                                                                </tr>
                                                            }
                                                            <tr><td colSpan={5}></td></tr>
                                                            <tr><th colSpan={5} style={{ textAlign: "center" }} >ELECTRICAL</th></tr>
                                                            {!!(salesOrderTracking.mountingInstructions?.filter(
                                                                (mi) => mi.detailTypeId == 4)
                                                                ?? []
                                                            ).length &&
                                                                (salesOrderTracking.mountingInstructions?.filter(
                                                                    (mi) => mi.detailTypeId == 4) ?? []).map((mi) =>
                                                                        <tr key={mi.id} >
                                                                            <td>
                                                                                <FormGroup check inline >
                                                                                    <Input type="checkbox" className='ml-2' checked={!!mi.isCompleted} onChange={(e) => this.onMountingInstructionCheckboxChange(e, mi.id)} />
                                                                                    {!!mi.isCompleted &&
                                                                                        <Tooltip title={`Completed by ${mi.modifiedByUser} ${moment.utc(mi.modifiedOn).local().format('MM-DD-YYYY HH:mm:ss')}`} placement="top" className="ml-2" arrow>
                                                                                            <small className="text-muted font-italic">{mi.modifiedByUser}</small>
                                                                                        </Tooltip>}
                                                                                </FormGroup>
                                                                            </td>
                                                                            <td>{mi.quantity}</td>
                                                                            <td>{mi.partNumber}</td>
                                                                            <td>{mi.description}</td>
                                                                            <td>{mi.instructions.join(', ')}</td>
                                                                        </tr>
                                                                    )
                                                            }
                                                            {!!!(salesOrderTracking.mountingInstructions?.filter(
                                                                (mi) => mi.detailTypeId == 4)
                                                                ?? []
                                                            ).length &&
                                                                <tr>
                                                                    <td colSpan={5} style={{ textAlign: "center" }} >No Sales Order Electrical Instructions</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        }
                                    </TabPane>
                                    <TabPane tabId="3">
                                        {!hasLoaded.drawings &&
                                            <h1 style={{ textAlign: 'center' }}><i className="fa fa-spin fa-circle-notch text-danger"></i></h1>
                                        }
                                        {hasLoaded.drawings &&
                                            <Row>
                                                <Col sm={12} md={6} lg={6}>
                                                    <Table striped>
                                                        <thead>
                                                            <tr><th>Electrical Drawings</th></tr>
                                                        </thead>
                                                        <tbody>
                                                            {!!(salesOrderTracking.electricalDrawings ?? []).length &&
                                                                (salesOrderTracking.electricalDrawings ?? []).map((ed) =>
                                                                    <tr key={ed.id} >
                                                                        <td className="faux-link">
                                                                            <span className={'mr-2'} >{ed.number}</span>
                                                                            <SmallButton
                                                                                type="button"
                                                                                className="document-actions mr-2"
                                                                                onClick={() => this.onDownloadDrawing(ed.id)}
                                                                            >
                                                                                <i className={`fa fa-download fa-md`} />
                                                                            </SmallButton>
                                                                            <SmallButton
                                                                                type="button"
                                                                                className="document-actions mr-2"
                                                                                onClick={() => this.onShowDrawing(ed.id)}
                                                                            >
                                                                                <i className={`fa fa-eye fa-md`} />
                                                                            </SmallButton>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            {!!!(salesOrderTracking.electricalDrawings ?? []).length &&
                                                                <tr>
                                                                    <td>No Electrical Drawings</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                                <Col sm={12} md={6} lg={6} >
                                                    <Table striped>
                                                        <thead>
                                                            <tr><th>Hydraulic Drawings</th></tr>
                                                        </thead>
                                                        <tbody>
                                                            {!!(salesOrderTracking.hydraulicDrawings ?? []).length &&
                                                                (salesOrderTracking.hydraulicDrawings ?? []).map((ed) =>
                                                                    <tr key={ed.id} >
                                                                        <td className="faux-link">
                                                                            <span className={'mr-2'} >{ed.number}</span>
                                                                            <SmallButton
                                                                                type="button"
                                                                                className="document-actions mr-2"
                                                                                onClick={() => this.onDownloadDrawing(ed.id)}
                                                                            >
                                                                                <i className={`fa fa-download fa-md`} />
                                                                            </SmallButton>
                                                                            <SmallButton
                                                                                type="button"
                                                                                className="document-actions mr-2"
                                                                                onClick={() => this.onShowDrawing(ed.id)}
                                                                            >
                                                                                <i className={`fa fa-eye fa-md`} />
                                                                            </SmallButton>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            {!!!(salesOrderTracking.hydraulicDrawings ?? []).length &&
                                                                <tr>
                                                                    <td>No Hydraulic Drawings</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        }
                                        {hasLoaded.mediaItems &&
                                            !!(salesOrderTracking.mediaItemCategories ?? []).length && hasLoaded.drawings &&
                                                (salesOrderTracking.mediaItemCategories ?? []).map((mic) =>
                                                    <Row key={uuidv4()} >
                                                        <Col>
                                                            <Table striped>
                                                                <thead>
                                                                    <tr><th>ERP: {mic}</th></tr>
                                                                </thead>
                                                                <tbody>
                                                                    {!!(salesOrderTracking.mediaItems ?? []).length &&
                                                                        !!salesOrderTracking.mediaItems.some(mi => mi.category == mic)
                                                                        && (salesOrderTracking.mediaItems.filter((mi) =>mi.category == mic) ?? []).map((mi) =>
                                                                            <tr key={mi.recordId} >
                                                                                <td className="faux-link">
                                                                                    <span className={"mr-2"}>{mi.name}</span>
                                                                                    <SmallButton
                                                                                        type="button"
                                                                                        className="document-actions mr-2"
                                                                                        onClick={() => this.onDownloadMedia(mi.recordId)}
                                                                                    >
                                                                                        <i className={`fa fa-download fa-md`} />
                                                                                    </SmallButton>
                                                                                    <SmallButton
                                                                                        type="button"
                                                                                        className="document-actions mr-2"
                                                                                        onClick={() => this.onShowMedia(mi.recordId)}
                                                                                    >
                                                                                        <i className={`fa fa-eye fa-md`} />
                                                                                    </SmallButton>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                    {!!!(salesOrderTracking.mediaItems.filter((mi) => mi.category == mic) ?? []).length &&
                                                                        <tr>
                                                                            <td>No Items</td>
                                                                        </tr>
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                )
                                        }
                                    </TabPane>
                                    <TabPane tabId="4">
                                        {!hasLoaded.workOrders &&
                                            <h1 style={{ textAlign: 'center' }}><i className="fa fa-spin fa-circle-notch text-danger"></i></h1>
                                        }
                                        {hasLoaded.workOrders && 
                                            <Row>
                                                {!(salesOrderTracking.workOrders ?? []).lentgh &&
                                                    (salesOrderTracking.workOrders ?? []).sort((a, b) => a.sequence - b.sequence).map((wo) =>
                                                        <Col key={uuidv4()} xs={12} sm={6} md={4} lg={2} style={{ paddingBottom: '1em' }}>
                                                            <Card style={{ textAlign: "center", height: "10.5em", border: wo.syncWorkCenterName !== 'None' ? '2px solid ' + (wo.status == 'Complete' ? 'rgb(5, 242, 33)' : 'rgb(242, 230,5)')  : '2px solid rgba(0, 0, 0, 0.125)' }}>
                                                                <CardBody style={{ backgroundColor: wo.status == 'Complete' ? "#ebffd9" : "#fff2ca" }} >
                                                                    <CardTitle tag="h5" style={{ height: '3em', cursor: "pointer" }} onClick={() => this.onNavigateToWorkflow(wo.mappedWorkCenterId)} >
                                                                        <FontAwesomeIcon size='sm' icon={wo.syncWorkCenterIcon} />
                                                                        {' '}
                                                                        {wo.syncWorkCenterName !== 'None' ? wo.syncWorkCenterName : wo.apteanWorkCenterName}
                                                                    </CardTitle>
                                                                    <CardText>
                                                                        <span style={{ display: "inline-block", minHeight: '21px' }} >
                                                                            {(!!(wo.predecessors ?? []).length || !!(wo.successors ?? []).length) &&
                                                                                <FontAwesomeIcon style={{ float: 'bottom' }} size='sm' icon="fa-cogs" onClick={() => this.toggleSequence(wo)} />
                                                                            }
                                                                        </span>
                                                                    </CardText>
                                                                    {wo.status == 'Complete' &&
                                                                        <div className={'dateFooter'} >
                                                                            <span>Completed On</span>
                                                                            <span>{wo.completedDate}</span>
                                                                        </div>
                                                                    }
                                                                    {wo.status != 'Complete' &&
                                                                        <div className={'dateFooter'} >
                                                                            <span>Started On</span>
                                                                            <span>{wo.startDate}</span>
                                                                        </div>
                                                                    }
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    )
                                                }
                                                {!!(salesOrderTracking.workOrders ?? []).lentgh &&
                                                    <Col key={uuidv4()} xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: '1em' }}>
                                                        <h1 style={{ textAlign: 'center' }} >No Work Orders</h1>
                                                    </Col>
                                                }
                                            </Row>
                                        }
                                    </TabPane>
                                    <TabPane tabId="5">
                                        {!hasLoaded.sourceDocuments &&
                                            <h1 style={{ textAlign: 'center' }}><i className="fa fa-spin fa-circle-notch text-danger"></i></h1>
                                        }
                                        {hasLoaded.sourceDocuments &&
                                            <>
                                                <Row style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                                                    <Col sm={6} md={4} lg={2}>
                                                        <FormLabel
                                                            htmlFor="salesOrderDocumentName"
                                                            text="Sales Order Document"
                                                        />
                                                    </Col>
                                                    <Col className="document-column" sm={6} md={8} lg={10}>
                                                        <span
                                                            id="salesOrderDocumentName"
                                                            name="salesOrderDocumentName"
                                                            className="form-control-sm form-control-plaintext"
                                                        >
                                                            {salesOrderTracking.salesOrderDocumentName}
                                                            <SmallButton
                                                                type="button"
                                                                className="document-actions ml-2"
                                                                onClick={() => this.onDownloadDocument(salesOrderTracking.salesOrderDocumentId)}
                                                            >
                                                                <i className={`fa fa-download fa-md`} />
                                                            </SmallButton>
                                                        </span>

                                                    </Col>
                                                </Row>
                                                <Row style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                                                    <Col sm={6} md={4} lg={2}>
                                                        <FormLabel
                                                            htmlFor="workOrderDocumentName"
                                                            text="Work Order Document"
                                                        />
                                                    </Col>
                                                    <Col className="document-column" sm={6} md={8} lg={10}>
                                                        <span id="workOrderDocumentName"
                                                            name="workOrderDocumentName"
                                                            className="form-control-sm form-control-plaintext"
                                                        >
                                                            {salesOrderTracking.workOrderDocumentName}
                                                            <SmallButton
                                                                type="button"
                                                                className="document-actions ml-2"
                                                                onClick={() => this.onDownloadDocument(salesOrderTracking.workOrderDocumentId)}
                                                            >
                                                                <i className={`fa fa-download fa-md`} />
                                                            </SmallButton>
                                                        </span>

                                                    </Col>
                                                </Row>
                                                <Row style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                                                    <Col sm={6} md={4} lg={2}>
                                                        <FormLabel
                                                            htmlFor="mountingInstructionsDocumentName"
                                                            text="Mounting Instructions Document"
                                                        />
                                                    </Col>
                                                    <Col className="document-column" sm={6} md={8} lg={10}>
                                                        <span
                                                            id="mountingInstructionsDocumentName"
                                                            name="mountingInstructionsDocumentName"
                                                            className="form-control-sm form-control-plaintext"
                                                        >
                                                            {salesOrderTracking.mountingInstructionsDocumentName}
                                                            <SmallButton
                                                                type="button"
                                                                className="document-actions ml-2"
                                                                onClick={() => this.onDownloadDocument(salesOrderTracking.mountingInstructionsDocumentId)}
                                                            >
                                                                <i className={`fa fa-download fa-md`} />
                                                            </SmallButton>
                                                        </span>

                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                    </TabPane>
                                    <TabPane tabId="6">
                                        {!hasLoaded.workCenterForms &&
                                            <h1 style={{ textAlign: 'center' }}><i className="fa fa-spin fa-circle-notch text-danger"></i></h1>
                                        }
                                        {hasLoaded.workCenterForms &&
                                            <>
                                                <DataGridToolbar
                                                    entity="SalesOrderTrackingSurveySearchResultDTO"
                                                    gridApi={this.state.gridApi}
                                                    dataSource={this.state.dataSource}
                                                    hideAdd={true}
                                                    hideExcelButton={true}
                                                    gridOptions={this.state.gridOptions}
                                                >
                                                </DataGridToolbar>
                                                <DataGrid
                                                    domLayout={"normal"}
                                                    rowData={rowData}
                                                    gridOptions={gridOptions}
                                                    gridStatus={this.state.gridStatus}
                                                />
                                            </>
                                        }
                                    </TabPane>
                                    <TabPane tabId="7">
                                        {!hasLoaded.workflowTags &&
                                            <h1 style={{ textAlign: 'center' }}><i className="fa fa-spin fa-circle-notch text-danger"></i></h1>
                                        }
                                        {hasLoaded.workflowTags &&
                                            <>
                                                <Row>
                                                    <Col sm={6} md={6} lg={6} >
                                                        <FormGroup>
                                                            <FormLabel
                                                                htmlFor="workflowTagsAdd"
                                                                text="Tags to Add"
                                                                helpMessage="Tags you want to add to this order tracking"
                                                            />
                                                            <ValidatedSelect
                                                                id="workflowTagsAdd"
                                                                name="workflowTagsToAdd"
                                                                options={workFlowStatusTags}
                                                                isMulti
                                                                value={workflowTagsToAdd ?? ''}
                                                                getOptionLabel={option => option.status}
                                                                getOptionValue={option => option.id}
                                                                onChange={(selection) => {
                                                                    workflowTagsToAdd = selection;
                                                                    this.setState({ workflowTagsToAdd: workflowTagsToAdd });
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={3} md={3} lg={3} >
                                                        {(!!(workflowTagsToAdd ?? []).length || !!(workflowTagsToRemove ?? []).length) &&
                                                            <Button
                                                                size="sm"
                                                                type="button"
                                                                color="primary"
                                                                name="saveWorkflowTags"
                                                                disabled={!!tagsSaving}
                                                                onClick={this.onMakeWorkflowChanges}
                                                            >
                                                                <FontAwesomeIcon size="lg" icon={tagsSaving ? "fa-circle-notch" : "fa-save"} className={saving ? "mr-2 faSaveSpinner" : "mr-2"} />
                                                                {tagsSaving ? 'Saving, Please Wait...' : 'Save Changes'}
                                                            </Button>
                                                        }
                                                    </Col>
                                                    <Col sm={3} md={3} lg={3} >
                                                        {(!!(workflowTagsToAdd ?? []).length || !!(workflowTagsToRemove ?? []).length) &&
                                                            <Button
                                                                size="sm"
                                                                type="button"
                                                                color="secondary"
                                                                name="cancelWorkflowTags"
                                                                onClick={this.onClearWorkCenterTags}
                                                                className="ml-2">
                                                                <FontAwesomeIcon className="mr-2" icon="fa-times" />
                                                                {"Cancel"}
                                                            </Button>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} md={12} lg={12} >
                                                        <Table id="workflowTagsTable" striped>
                                                            <colgroup>
                                                                <col style={{ width: '90%' }} />
                                                                <col style={{ width: '10%' }} />
                                                            </colgroup>
                                                            <thead>
                                                                <tr>
                                                                    <th>Tag</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {!!((salesOrderTracking.workFlowTags ?? []).length) &&
                                                                    (salesOrderTracking.workFlowTags ?? []).map((wft) =>
                                                                        <tr key={wft.id} className={(wft.deleted ?? false) ? 'deleted' : ''}>
                                                                            <td>{wft.workFlowStatusTag_Name}</td>
                                                                            <td>
                                                                                {!!!wft.deleted &&
                                                                                    <Button
                                                                                        size="sm"
                                                                                        type="button"
                                                                                        color="secondary"
                                                                                        name="removeWorkflowTag"
                                                                                        onClick={() => {
                                                                                            wft.deleted = true;
                                                                                            workflowTagsToRemove.push(wft);
                                                                                            this.setState({ salesOrderTracking: salesOrderTracking });
                                                                                        }}
                                                                                        className="ml-2">
                                                                                        <FontAwesomeIcon icon="fa-trash" />
                                                                                    </Button>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                {!!!((salesOrderTracking.workFlowTags ?? []).length) &&
                                                                    <tr>
                                                                        <td colSpan="2">No Assigned Tags</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                    </TabPane>
                                    <TabPane tabId="8">
                                        {!hasLoaded.quote &&
                                            <h1 style={{ textAlign: 'center' }}><i className="fa fa-spin fa-circle-notch text-danger"></i></h1>
                                        }
                                        {hasLoaded.quote &&
                                            <Row>
                                                <Col sm={12} >
                                                    <Row>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel htmlFor="quote.number" text="Number:" />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="quote.number"
                                                                name="quote.number"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{quote.number}</span>
                                                        </Col>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel htmlFor="quote.model" text="Model:" />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="quote.model"
                                                                name="quote.model"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{quote.model}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel htmlFor="quote.receivedDate" text="Received Date:" />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="quote.receivedDate"
                                                                name="quote.receivedDate"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{moment(quote.receivedDate).toDate().toLocaleDateString('en-US')}</span>
                                                        </Col>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel htmlFor="quote.quoteDealerCustomerName" text="Dealer:" />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="quote.quoteDealerCustomerName"
                                                                name="quote.quoteDealerCustomerName"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{quote.quoteDealerCustomerName}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel htmlFor="quote.dueDate" text="Due Date:" />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="quote.dueDate"
                                                                name="quote.dueDate"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{moment(quote.dueDate).toDate().toLocaleDateString('en-US')}</span>
                                                        </Col>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel htmlFor="quote.territoryName" text="Territory:" />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="territoryName"
                                                                name="territoryName"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{quote.territoryName}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel htmlFor="quote.quoteCustomer.name" text="End User:" />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="quote.quoteCustomer.name"
                                                                name="quote.quoteCustomer.name"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{quote.quoteCustomer.name}</span>
                                                        </Col>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel htmlFor="quote.dealerSalesmanName" text="Dealer Salesman:" />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="quote.dealerSalesmanName"
                                                                name="quote.dealerSalesmanName"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{quote.dealerSalesmanName}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel htmlFor="quote.liftTypeText" text="Lift Type:" />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="quote.liftTypeText"
                                                                name="quote.liftTypeText"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{quote.liftTypeText}</span>
                                                        </Col>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel htmlFor="quote.dealerSalesmanEmail" text="Dealer Salesman Email:" />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="quote.dealerSalesmanEmail"
                                                                name="quote.dealerSalesmanEmail"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{quote.dealerSalesmanEmail}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6} md={2} lg={2}>
                                                            <FormLabel htmlFor="quote.poNumber" text="PO Number:" />
                                                        </Col>
                                                        <Col sm={6} md={4} lg={4}>
                                                            <span id="quote.poNumber"
                                                                name="quote.poNumber"
                                                                className="form-control-sm form-control-plaintext"
                                                            >{quote.poNumber}</span>
                                                        </Col>
                                                        <Col sm={6} md={2} lg={2}></Col>
                                                        <Col sm={6} md={4} lg={4}></Col>
                                                    </Row>
                                                    <Table striped style={{ borderTop: "4px solid" }}>
                                                        <colgroup>
                                                            <col style={{ width: '12%' }}></col>
                                                            <col style={{ width: '12%' }}></col>
                                                            <col style={{ width: '25%' }}></col>
                                                            <col style={{ width: '56%' }}></col>
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                <th>Line Number</th>
                                                                <th>QTY</th>
                                                                <th>Unit Price</th>
                                                                <th>Item</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!!(quoteLines ?? []).length &&
                                                                quoteLines.sort(
                                                                    (ql1, ql2) => ql1.lineNumber < ql2.lineNumber
                                                                ).map((ql) =>
                                                                    <tr key={uuidv4()} >
                                                                        <td>{ql.lineNumber }</td>
                                                                        <td>{ql.quantity}</td>
                                                                        <td>{ql.unitPrice}</td>
                                                                        <td>{ql.item_Id}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                            {!!!(quoteLines ?? []).length &&
                                                                <tr>
                                                                    <td colSpan={4} style={{ textAlign: "center" }} >No Quote Lines</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        }
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </>
                    }
                    {!!!(salesorderTrackingId) && addByMethod != 1
                        /*add by sales order*/
                        && <div>
                            <GroupedRow>
                                <FormGroupColumn>
                                    <FormGroup >
                                        <FormLabel htmlFor="salesOrderToCreae"
                                            text="Choose Order to create Tracking for"
                                            required={addByMethod !== 1}
                                        />
                                        <ValidatedSelect
                                            id="salesOrderToCreate"
                                            name="chosenSO"
                                            required={addByMethod !== 1}
                                            options={trackableSO}
                                            value={(trackableSO ?? []).find(s => s.value == this.state.chosenSO) ?? ''}
                                            onChange={(selection, event) => {
                                                chosenSO = selection.value;
                                                this.setState({ chosenSO: chosenSO });
                                            }}
                                        /> 
                                    </FormGroup>
                                    <FormGroup >
                                        <FormLabel htmlFor="liftTypeForSalesOrderToCreate"
                                            text="Choose Lift Type of Order To Create"
                                            required={addByMethod !== 1}
                                        />
                                        <ValidatedSelect
                                            id="liftTypeForSalesOrderToCreate"
                                            name="chosenSOLT"
                                            required={addByMethod !== 1}
                                            options={liftTypes}
                                            value={(liftTypes ?? []).find(s => s.value == this.state.chosenLiftType) ?? ''}
                                            onChange={(selection, event) => {
                                                chosenLiftType = selection.value;
                                                this.setState({ chosenLiftType: chosenLiftType });
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Button type="button"
                                            className="mr-3"
                                            color="success"
                                            disabled={!!saving}
                                            onClick={this.onSubmit}>
                                            <FontAwesomeIcon size="lg" icon={saving ? "fa-circle-notch" : "fa-save"} className="mr-2" />
                                            {saving ? 'Saving, Please Wait...' : 'Create Order Tracking'}
                                        </Button>
                                    </FormGroup>
                                    <FormGroup>
                                        <Button type="button"
                                            className="mr-3"
                                            disabled={!!saving}
                                            onClick={() => this.props.history.push(`${AppNavPaths.SalesOrderTracking}`)}>
                                            <FontAwesomeIcon size="lg" icon={"fa-times"} className="mr-2" />
                                            {"Cancel"}
                                        </Button>
                                    </FormGroup>
                                </FormGroupColumn>
                            </GroupedRow>
                        </div>
                    }
                    {!!!(salesorderTrackingId) && addByMethod == 1
                        /*Add by file*/
                        && <div style={{
                                margin: "0 0.5em 0 0.5em",
                                padding: '1em',
                            }}
                        >
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="quote.serialNumber" text="Serial Number" />
                                        <input
                                            autoComplete="off"
                                            name="quote.serialNumber"
                                            value={!!(quote.serialNumber) ? quote.serialNumber : "Not Assigned"}
                                            className="form-control"
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="customerNumber"
                                            text="Dealer"
                                            required={!!(addByMethod == 1)}
                                        />
                                        <ValidatedSelect
                                            id="customerNumber"
                                            name="chosenCust"
                                            required={!!(addByMethod == 1)}
                                            isDisabled={true}
                                            options={customers}
                                            value={(customers ?? []).find(s => s.value == chosenCust) ?? ''}
                                            onChange={(selection) => {
                                                chosenCust = selection?.value;
                                                this.setState({ chosenCust: chosenCust });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="productFamily"
                                            text="Choose Product Family"
                                            required={!!(addByMethod == 1)}
                                        />
                                        <ValidatedSelect
                                            id="productFamily"
                                            name="chosenProdFam"
                                            required={addByMethod == 1}
                                            options={prodFams}
                                            value={(prodFams ?? []).find(s => s.value == chosenProdFam) ?? ''}
                                            onChange={(selection) => {
                                                chosenProdFam = selection?.value;
                                                this.setState({ chosenProdFam: chosenProdFam });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="productCategory"
                                            text="Choose Product category"
                                            required={!!(addByMethod == 1)}
                                        />
                                        <ValidatedSelect
                                            id="productCategory"
                                            name="chosenProdCat"
                                            required={!!(addByMethod == 1)}
                                            options={prodCats}
                                            value={(prodCats ?? []).find(s => s.value == chosenProdCat) ?? ''}
                                            onChange={(selection) => {
                                                chosenProdCat = selection?.value;
                                                this.setState({ chosenProdCat: chosenProdCat });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="orderDate"
                                            text="Order Date"
                                            required={!!(addByMethod == 1)}
                                        />
                                        <DatePicker selected={fileCreation.orderDate}
                                            autoComplete='off'
                                            required={!!(addByMethod == 1)}
                                            id="orderDate"
                                            name="orderDate"
                                            className="form-control"
                                            onChange={
                                                (date) => {
                                                    fileCreation.orderDate = date;
                                                    fileCreation.requiredDate = new Date(date);
                                                    fileCreation.requiredDate.setFullYear(date.getFullYear() + 2)
                                                    this.setState({ fileCreation: fileCreation });
                                                }
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="promiseDate"
                                            text="Promise Date"
                                            required={!!(addByMethod == 1)}
                                        />
                                        <DatePicker selected={fileCreation.promiseDate}
                                            required={!!(addByMethod == 1)}
                                            autoComplete='off'
                                            id="promiseDate"
                                            name="promiseDate"
                                            className="form-control"
                                            onChange={
                                                (date) => {
                                                    fileCreation.promiseDate = date;
                                                    this.setState({ fileCreation: fileCreation });
                                                }
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="requiredDate"
                                            text="Required Date"
                                            required={!!(addByMethod == 1)}
                                        />
                                        <DatePicker selected={fileCreation.requiredDate}
                                            autoComplete='off'
                                            required={!!(addByMethod == 1)}
                                            id="requiredDate"
                                            name="requiredDate"
                                            className="form-control"
                                            disabled={true}
                                            onChange={
                                                (date) => {
                                                    fileCreation.requiredDate = date;
                                                    this.setState({ fileCreation: fileCreation });
                                                }
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {/*Sales Order  file*/}
                            <Row style={{
                                margin: "1em 0.5em 0 0.5em",
                                borderTop: "2px solid lightgray",
                                borderLeft: "2px solid lightgray",
                                padding: '1em',
                                borderRight: '2px solid lightgray'
                            }} >
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="salesOrderFileName"
                                            text="Quote File Name"
                                            required={!!(addByMethod == 1)}
                                        />
                                        <Input
                                            required={!!(addByMethod == 1)}
                                            id="salesOrderFileName"
                                            name="salesOrderFileName"
                                            className="form-control"
                                            disabled={true}
                                            value={fileCreation.salesOrderFileName ?? ''}
                                            onChange={ev => {
                                                const val = ev?.target?.value;
                                                fileCreation.salesOrderFileName = val;
                                                this.setState({ fileCreation: fileCreation });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="salesOrderFileDescription"
                                            text="Quote File Description"
                                        />
                                        <Input
                                            type="textarea"  
                                            disabled={true}
                                            name="salesOrderFileDescription"
                                            value={fileCreation.salesOrderFileDescription ?? ''}
                                            onChange={(evt) => {
                                                let value = evt.target.value;
                                                fileCreation.salesOrderFileDescription = value;
                                                this.setState({ fileCreation: fileCreation });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{
                                margin: "0 0.5em 0 0.5em",
                                borderBottom: "2px solid lightgray",
                                borderLeft: "2px solid lightgray",
                                padding: '1em',
                                borderRight: '2px solid lightgray'
                            }}>
                                <Col>
                                    <Dropzone
                                        /*Only Accept excel files*/
                                        accept={acceptProp}
                                        multiple={false}
                                        onDrop={(acceptedFiles) => {
                                            let { fileCreation, saving } = { ...this.state };

                                            if (!!saving) {
                                                return false;
                                            }

                                            //We are only uploading a single file at a time and only the first in the list
                                            fileCreation.salesOrderFile = { file: acceptedFiles[0], progress: 0.0 };
                                            if (!(fileCreation.salesOrderFileName.length)) {
                                                //No file name assigned so make it the same as this file's name
                                                fileCreation.salesOrderFileName = acceptedFiles[0].name;
                                            }

                                            this.setState({ fileCreation: fileCreation });
                                        }}
                                        required={addByMethod == 1}
                                        inputContent={(files, extra) => (extra.reject ? 'File type not permitted.' : 'Drag and drop your sales order file here, or click to select a file')}
                                        maxSize={25000000} /*25MB application wide*/
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <>
                                                {
                                                    !!(fileCreation.salesOrderFile) &&
                                                    <div className="file-uploads-preview">
                                                        <FileLink
                                                            viewOnly={true}
                                                            preview={true}
                                                            showFileSize={true}
                                                            key={fileCreation.salesOrderFile.url}
                                                            url={fileCreation.salesOrderFile.url}
                                                            file={null}
                                                            remove={() => this.remove('salesOrder')}
                                                            progress={fileCreation.salesOrderFile.progress}
                                                        />
                                                    </div>
                                                }
                                                {!!!(fileCreation.salesOrderFile) &&
                                                    <section className="file-upload-section" hidden={!!saving}>
                                                        <div {...getRootProps({ className: 'dropzone' })} className="file-upload-section-inner">
                                                            <input {...getInputProps()} />
                                                            <span className="border-bottom mb-2">Drag and drop your quote file here, or click to select a file.</span>
                                                            <small>Supported file types:<span className="ml-2 text-success">{"MS Excel Files (.xls, .xlsx)"}</span></small>
                                                        </div>
                                                    </section>
                                                }
                                            </>
                                        )}
                                    </Dropzone>
                                </Col>
                            </Row>
                            {/*Work Order file*/}
                            <Row style={{
                                margin: "1em 0.5em 0 0.5em",
                                borderTop: "2px solid lightgray",
                                borderLeft: "2px solid lightgray",
                                padding: '1em',
                                borderRight: '2px solid lightgray'
                            }}>
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="workOrderFileName"
                                            text="Work Order File Name"
                                            required={!!(addByMethod == 1)} />
                                        <Input
                                            required={!!(addByMethod == 1)}
                                            id="workOrderFileName"
                                            name="workOrderFileName"
                                            className="form-control"
                                            value={fileCreation.workOrderFileName ?? ''}
                                            onChange={ev => {
                                                const val = ev?.target?.value;
                                                fileCreation.workOrderFileName = val;
                                                this.setState({ fileCreation: fileCreation });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="workOrderFileDescription"
                                            text="Work Order File Description"
                                        />
                                        <Input
                                            type="textarea"
                                            name="workOrderFileDescription"
                                            value={fileCreation.workOrderFileDescription ?? ''}
                                            onChange={(evt) => {
                                                let value = evt.target.value;
                                                fileCreation.workOrderFileDescription = value;
                                                this.setState({ fileCreation: fileCreation });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{
                                margin: "0 0.5em 0 0.5em",
                                borderBottom: "2px solid lightgray",
                                borderLeft: "2px solid lightgray",
                                padding: '1em',
                                borderRight: '2px solid lightgray'
                            }}>
                                <Col>
                                    <Dropzone
                                        /*Only Accept excel files*/
                                        accept={acceptProp}
                                        multiple={false}
                                        onDrop={(acceptedFiles) => {
                                            let { fileCreation, saving } = { ...this.state };

                                            if (!!saving) {
                                                return false;
                                            }

                                            //We are only uploading a single file at a time and only the first in the list
                                            fileCreation.workOrderFile = { file: acceptedFiles[0], progress: 0.0 };
                                            fileCreation.workOrderFileName = acceptedFiles[0].name; //Always overwrite file name on drop

                                            this.setState({ fileCreation: fileCreation });
                                        }}
                                        inputContent={(files, extra) => (extra.reject ? 'File type not permitted.' : 'Drag and drop your work order file here, or click to select a file')}
                                        maxSize={25000000} /*25MB application wide*/
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <>
                                                {
                                                    !!(fileCreation.workOrderFile) &&
                                                    <div className="file-uploads-preview">
                                                        <FileLink
                                                            preview={true}
                                                            showFileSize={true}
                                                            key={fileCreation.workOrderFile.file.path}
                                                            url={'#'}
                                                            file={fileCreation.workOrderFile.file}
                                                            remove={() => this.remove('workOrder')}
                                                            progress={fileCreation.workOrderFile.progress}
                                                        />
                                                    </div>
                                                }
                                                {!!!(fileCreation.workOrderFile) &&
                                                    <section className="file-upload-section" hidden={!!saving}>
                                                        <div {...getRootProps({ className: 'dropzone' })} className="file-upload-section-inner">
                                                            <input {...getInputProps()} />
                                                            <small className="invalid-feedback text-danger" hidden>Work Order file is required.</small>
                                                            <span className="border-bottom mb-2">Drag and drop your work order file here, or click to select a file.</span>
                                                            <small>Supported file types:<span className="ml-2 text-success">{"MS Excel Files (.xls, .xlsx)"}</span></small>
                                                        </div>
                                                    </section>
                                                }
                                            </>
                                        )}
                                    </Dropzone>
                                </Col>
                            </Row>
                            {/*Mounting Instructions file*/ }
                            
                            <Row style={{
                                margin: "1em 0.5em 0 0.5em",
                                borderTop: "2px solid lightgray",
                                borderLeft: "2px solid lightgray",
                                padding: '1em',
                                borderRight: '2px solid lightgray'
                            }}>
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="mountingInstructionsFileName"
                                            text="Mounting Instructions File Name"
                                            required={!!(addByMethod == 1)} />
                                        <Input
                                            required={!!(addByMethod == 1)}
                                            id="mountingInstructionsFileName"
                                            name="mountingInstructionsFileName"
                                            className="form-control"
                                            value={fileCreation.mountingInstructionsFileName ?? ''}
                                            onChange={ev => {
                                                const val = ev?.target?.value;
                                                fileCreation.mountingInstructionsFileName = val;
                                                this.setState({ fileCreation: fileCreation });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <FormLabel htmlFor="mountingInstructionsFileDescription"
                                            text="Mounting Instructions File Description"
                                        />
                                        <Input
                                            type="textarea"
                                            name="mountingInstructionsFileDescription"
                                            value={fileCreation.mountingInstructionsFileDescription ?? ''}
                                            onChange={(evt) => {
                                                let value = evt.target.value;
                                                fileCreation.mountingInstructionsFileDescription = value;
                                                this.setState({ fileCreation: fileCreation });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{
                                margin: "0 0.5em 0 0.5em",
                                borderBottom: "2px solid lightgray",
                                borderLeft: "2px solid lightgray",
                                padding: '1em',
                                borderRight: '2px solid lightgray'
                            }}>
                                <Col>
                                    <Dropzone
                                        /*Only Accept excel files*/
                                        accept={acceptProp}
                                        multiple={false}
                                        onDrop={(acceptedFiles) => {
                                            let { fileCreation, saving } = { ...this.state };

                                            if (!!saving) {
                                                return false;
                                            }

                                            //We are only uploading a single file at a time and only the first in the list
                                            fileCreation.mountingInstructionsFile = { file: acceptedFiles[0], progress: 0.0 };
                                            fileCreation.mountingInstructionsFileName = acceptedFiles[0].name;

                                            this.setState({ fileCreation: fileCreation });
                                        }}
                                        inputContent={(files, extra) => (extra.reject ? 'File type not permitted.' : 'Drag and drop your mounting instructions file here, or click to select a file')}
                                        maxSize={25000000} /*25MB application wide*/
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <>
                                                {
                                                    !!(fileCreation.mountingInstructionsFile) &&
                                                    <div className="file-uploads-preview">
                                                        <FileLink
                                                            preview={true}
                                                            showFileSize={true}
                                                            key={fileCreation.mountingInstructionsFile.file.path}
                                                            url={'#'}
                                                            file={fileCreation.mountingInstructionsFile.file}
                                                            remove={() => this.remove('mountInst')}
                                                            progress={fileCreation.mountingInstructionsFile.progress}
                                                        />
                                                    </div>
                                                }
                                                {!!!(fileCreation.mountingInstructionsFile) &&
                                                    <section className="file-upload-section" hidden={!!saving}>
                                                        <div {...getRootProps({ className: 'dropzone' })} className="file-upload-section-inner">
                                                            <input {...getInputProps()} />
                                                            <small className="invalid-feedback text-danger" hidden>Mounting Instructions file is required.</small>
                                                            <span className="border-bottom mb-2">Drag and drop your mounting instructions file here, or click to select a file.</span>
                                                            <small>Supported file types:<span className="ml-2 text-success">{"MS Excel Files (.xls, .xlsx)"}</span></small>
                                                        </div>
                                                    </section>
                                                }
                                            </>
                                        )}
                                    </Dropzone>
                                </Col>
                            </Row>
                            <Row style={{ padding: '1em' }}>
                                <Col sm={6} md={4} lg={4} >
                                    <FormGroup>
                                        <Button type="button"
                                            className="mr-3"
                                            color="success"
                                            disabled={!!saving}
                                            onClick={this.onSubmit}>
                                            <FontAwesomeIcon size="lg" icon={saving ? "fa-circle-notch" : "fa-save"} className={saving ? "mr-2 faSaveSpinner" : "mr-2" } />
                                            {saving ? 'Saving, Please Wait...' : 'Create Order Tracking'}
                                        </Button>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} md={4} lg={4} >
                                    <FormGroup>
                                        <Button type="button"
                                            className="mr-3"
                                            disabled={!!saving}
                                            onClick={() => this.props.history.push(`${AppNavPaths.SalesOrderTracking}`)}>
                                            <FontAwesomeIcon size="lg" icon={"fa-times"} className="mr-2" />
                                            {"Cancel"}
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>      
                        </div>
                    }
                </AppPageForm>
            </Fragment>
        );
    };
}