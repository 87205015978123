export class ShippingDTO {
    id = 0;
    shippingNumber = "";
    isComplete = false;
    shippingDetails = []; //list of ShippingDetailDTO
}

export class ShippingDetailDTO {
    id = 0;
    salesOrderTracking_Id = null;
    serialNumber = '';
    quoteNumber = '';
    salesOrderNumber = '';
    dealerCustomer = '';
    model = '';
    requiredDate = null;
    pickList = []; //list of ShippingPickListDTO
}

export class ShippingPickListDTO {
    id = 0;
    order = 0;
    completedBy = '';
    completedOn = null;
    component_Item_ID = '';
    component_Item = '';
    requiredQty = 0;
    isCompleted = false;
    isDeleted = false;
}

export class ShippingSearchResultDTO {
    id = 0;
    shippingNumber = "";
    shippingSerialNumbers = "";
    isComplete = false;
    createdOn = new Date();
}