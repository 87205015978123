import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import CommonContext from '../Common';
import SlideForm from '../common/forms/SlideForm';
import SalesOrderTrackingForm from './SalesOrderTrackingForm';

export default class SalesOrderTrackingSlideForm extends React.Component {
    //todo: hash this out, then add to the two pages

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            formTitle: 'Order Tracking',
            loading: true,
            show: false,
        }
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount = () => this.populateState();
    populateState = async () => this.setState({ loading: false });

    open = () => {
        this.context.setFormOpened(true);
        this.setState({
            show: true,
        }, () => {
                //callback after its visible
        });
    }

    onClose = () => {
        this.context.setFormOpened(false);
        this.setState({ show: false });
    }

    render() {
        //const { errors } = this.state;
        return (            
            <SlideForm
                loading={this.state.loading}
                show={this.state.show}
                id={this.props.id ?? "salesOrderTrackingSlideForm"}
                formIcon={faChartLine}
                formTitle={this.state.formTitle}
                ref={this.formRef}
                onClose={this.onClose}
                size={"col-xl-10 col-md-11 col-sm-11"}
                removeFormWrapper={true}
            >
                <SalesOrderTrackingForm isSlideFormOpen={this.state.show} routingHistory={this.props.routingHistory} match={{params: {id: this.props.salesOrderTrackingId, wcid: this.props.workCenterId}}} isNestedInSlideForm={true}/>
            </SlideForm>
        );
    }
}