import { createContext } from 'react';

const CommonContext = createContext();

export default CommonContext;

export const AppNavPaths = {
    Dashboard: '/',
    CameraTest: '/cameratest',
    MutableSettings: '/mutableSettings',
    Contacts: '/contacts',
    Employee: '/employee',
    EmployeeNew: '/newEmployee',
    EmployeeAccountStatuses: '/employeeAccountStatus',
    Employees: '/employees',    
    Group: '/group',
    Groups: '/groups',
    GroupNew: '/newGroup',
    NotFound: '/notFound',
    NoPermission: '/noPermission',
    Unauthorized: '/unauthorized',
    UserGroup: '/userGroup',
    UserGroups: '/userGroups',
    UserGroupNew: '/newUserGroup',
    Role: '/role',
    Roles: '/roles',
    RoleNew: '/newRole',
    Permission: '/permission',
    Permissions: '/permissions',
    PermissionNew: '/newPermission',
    //Workflow
    Quote: '/quote',
    Quotes: '/quotes',
    QuoteNew: '/newQuote',
    QuoteChangeOrder: '/changeorder',
    QuoteChangeOrders: '/changeorders',
    QuoteChangeOrderNew: '/newchangeorder',
    QuoteCustomers: '/quoteCustomers',
    QuoteCustomer: '/quoteCustomer',
    QuoteCustomerNew: '/newQuoteCustomer',
    QuotesWorkedOnBy: '/quotesWorkedOnBy',
    QuoteWorkedOnBy: '/quoteWorkedOnBy',
    QuoteWorkedOnByNew: '/quoteWorkedOnByNew',
    WorkCenter: '/workCenter',
    WorkCenterMap: '/workCenterMap',
    WorkCenterDiagram: '/workCenterDiagram',
    WorkCenters: '/workCenters',
    WorkCenterNew: '/newWorkCenter',
    WorkCenterWorkFlow: '/workflow',
    SalesOrderTracking: '/salesOrderTracking',
    SalesOrderTrackingSurvey: '/salesOrderTrackingSurvey',
    SalesOrderTrackingNew: '/salesOrderTracking/new',
    SalesOrderTrackingEngineerChangeOrder: '/salesOrderTracking/engineerchangeorder',
    Shipping: '/shipping',
    ShippingCreate: '/newshipping',
    WorkFlowStatusTag: '/workFlowStatusTag',
    WorkFlowStatusTags: '/workFlowStatusTags',
    WorkFlowStatusTagNew: '/newWorkFlowStatusTag',
    //Forms
    QuestionOptions: '/questionoptions',
    QuestionOptionsNew: '/questionoptions/new',
    Questions: '/questions',
    QuestionsNew: '/questions/new',
    UnitsOfMeasurement: '/units',
    UnitsOfMeasurementNew: '/units/new',
    Forms: '/forms',
    FormsBuild: '/forms/build',
    FormsNew: '/forms/new',
    QuestionGroups: '/questiongroups',
    QuestionGroupsNew: '/questiongroups/new',
    IssueTracking: '/issuetracking'
};

export const YesNoOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
];

export const ApiPrefix = '/api/v1';
export const ApiRoutes = {
    address: {
        create: () => `${ApiPrefix}/address`,
        update: (addressId) => `${ApiPrefix}/address/${addressId}`,
    },
    auth: {
        permissions: () => `${ApiPrefix}/authorization/permissions`,
        profile: () => `${ApiPrefix}/authorization/profile`,
        roles: () => `${ApiPrefix}/authorization/roles`,
        groups: () => `${ApiPrefix}/authorization/groups`,
        confirmPhone: (code) => `${ApiPrefix}/authorization/confirmPhone/${code}`,
    },
    employee: {
        suggestUsername: (name) => `${ApiPrefix}/employee/suggestUsername/${name}`,
        byId: (empId) => `${ApiPrefix}/employee/${empId}`,
        create: () => `${ApiPrefix}/employee`,
        update: (empId) => `${ApiPrefix}/employee/${empId}`,
        payRate: {
            create: (empId) => `${ApiPrefix}/employee/${empId}/payRate`,
            update: (empId, payRateId) =>
                `${ApiPrefix}/employee/${empId}/payRate/${payRateId}`,
        },
        groups: {
            all: () => `${ApiPrefix}/employees`,
        },
        rosterView: (empId) => `${ApiPrefix}/employee/${empId}/roster`,
    },
    employees: {
        all: () => `${ApiPrefix}/employees`,
        associatedWithContactActivity: () =>
            `${ApiPrefix}/employees/associatedWithContactActivity`,
        byCompany: (companyId) => `${ApiPrefix}/employees/${companyId}`,
        byDispatchLocationAndEquipmentType: (
            dispatchLocationId,
            equipmentTypeId
        ) =>
            `${ApiPrefix}/employees/byDispatchLocationAndEquipmentType/${dispatchLocationId}/${equipmentTypeId}`,
        search: () => `${ApiPrefix}/employees/search`,
        roster: () => `${ApiPrefix}/employees/roster`,
        rosterExport: () => `${ApiPrefix}/employees/rosterExport`,
        excelExport: () => `${ApiPrefix}/employees/excelExport`,
    },
    employeeAccountStatus: {
        byId: (employeeAccountStatusId) =>
            `${ApiPrefix}/employeeAccountStatus/${employeeAccountStatusId}`,
        create: () => `${ApiPrefix}/employeeAccountStatus`,
        update: (employeeAccountStatusId) =>
            `${ApiPrefix}/employeeAccountStatus/${employeeAccountStatusId}`,
        delete: (employeeAccountStatusId) =>
            `${ApiPrefix}/employeeAccountStatus/${employeeAccountStatusId}`,
    },
    quote: {
        all: () => `${ApiPrefix}/quote`,
        byId: (id) => `${ApiPrefix}/quote/${id}`,
        create: () => `${ApiPrefix}/quote`,
        update: (id) => `${ApiPrefix}/quote/${id}`,
        setdisposition: (id) => `${ApiPrefix}/quote/disposition/${id}`, 
        search: () => `${ApiPrefix}/quote/search`,
        excelExport: () => `${ApiPrefix}/quote/excelExport`,
        upload: () => `${ApiPrefix}/quote/upload`,
        quoteCustomerAll: () => `${ApiPrefix}/quote/quoteCustomer`,
        quoteCustomerSearch: () => `${ApiPrefix}/quote/quoteCustomer/search`,
        quoteCustomersExcelExport: () => `${ApiPrefix}/quote/quoteCustomer/excelExport`,
        quoteCustomerById: (id) => `${ApiPrefix}/quote/quoteCustomer/${id}`,
        quoteCustomerCreate: () => `${ApiPrefix}/quote/quoteCustomer`,
        quoteWorkedOnBySearch: () => `${ApiPrefix}/quote/quoteWorkedOnBy/search`,
        quoteWorkedOnByExcelExport: () => `${ApiPrefix}/quote/quoteWorkedOnBy/excelExport`,
        quoteWorkedOnByById: (id) => `${ApiPrefix}/quote/quoteWorkedOnBy/${id}`,
        quoteWorkedOnByCreate: () => `${ApiPrefix}/quote/quoteWorkedOnBy`,
        dealers: () => `${ApiPrefix}/quote/dealers`,
        workedonby: () => `${ApiPrefix}/quote/workedonby`,
        dispositions: () => `${ApiPrefix}/quote/dispositions`,
        nextNumber: () => `${ApiPrefix}/quote/nextQuoteNumber`,
        changeorder: {
            search: () => `${ApiPrefix}/quote/changeorder/search`,
            byId: (id) => `${ApiPrefix}/quote/changeorder/${id}`,
            cancel: (id) => `${ApiPrefix}/quote/changeorder/cancel/${id}`,
            reject: (id) => `${ApiPrefix}/quote/changeorder/reject/${id}`,
            complete: (id) => `${ApiPrefix}/quote/changeorder/complete/${id}`,
            fromfiles: () => `${ApiPrefix}/quote/changeorder/fromfiles`,
            create: (id, revision) => `${ApiPrefix}/quote/changeorder/create/${id}/${revision}`,
            excelExport: () => `${ApiPrefix}/quote/changeorder/excelExport`,
            clearableWorkCenters: () => `${ApiPrefix}/quote/changeorder/clearableworkcenters`,
            quoteEdit: (id) => `${ApiPrefix}/quote/changeorder/editstub/${id}`
        }
    },
    workCenter: {
        create: () => `${ApiPrefix}/workCenter`,
        byId: (id) => `${ApiPrefix}/workCenter/${id}`,
        update: (id) => `${ApiPrefix}/workCenter/${id}`,
        delete: (id) => `${ApiPrefix}/workCenter/${id}`,
        hasAccess: (id) => `${ApiPrefix}/workCenter/hasaccess/${id}`,
        workcentersbyuser: () => `${ApiPrefix}/workCenter/workcentersbyuser`,
        surveys: (id) => `${ApiPrefix}/workCenter/surveys/${id}`,
        workflow: () => `${ApiPrefix}/workCenter/workflow`,
        workflowTracking: (wcid, sotid) => `${ApiPrefix}/workCenter/workflow/${wcid}/${sotid}`,
        shippingTracking: (wcid, shpid) => `${ApiPrefix}/workCenter/shipping/${wcid}/${shpid}`,
        workflowExport: () => `${ApiPrefix}/workCenter/workflowexport`,
        workCenterSurvey: () => `${ApiPrefix}/workCenter/workflow/survey`,
        createWorkCenterSurvey: () => `${ApiPrefix}/workCenter/workflow/createsurvey`,
        workflowAnswerSurvey: () => `${ApiPrefix}/workCenter/workflow/answers`,
        workflowSubmitSurvey: () => `${ApiPrefix}/workCenter/workflow/submit`,
        workflowChangeFormStatus: () => `${ApiPrefix}/workcenter/workflow/changestatus`,
        workflowTrackingComplete: (wcid, sotid) => `${ApiPrefix}/workCenter/workflow/${wcid}/${sotid}/complete`,
        workflowDeleteAnswerDocument: (id) => `${ApiPrefix}/workCenter/workflow/answer/document/${id}`,
        exportsalesordersurvey: () => `${ApiPrefix}/workCenter/workflow/exportsurvey`,
        exportsalesordersurveygeneral: () => `${ApiPrefix}/workCenter/workflow/generalexportsurvey`,
        getWorkCenterActiveCount: (id) => `${ApiPrefix}/workCenter/getactivecount/${id}`,
        shipping: {
            picklistComplete: (wcid, plid) => `${ApiPrefix}/workCenter/shipping/${wcid}/picklist/${plid}/complete`,
            picklistClear: (wcid, plid) => `${ApiPrefix}/workCenter/shipping/${wcid}/picklist/${plid}/clear`,
        }
    },
    workCenters: {
        all: () => `${ApiPrefix}/workCenters`,
        allERP: () => `${ApiPrefix}/workCenters/erpWorkCenters`,
        search: () => `${ApiPrefix}/workCenters/search`,
        excelExport: () => `${ApiPrefix}/workCenters/excelExport`,
        getMap: () => `${ApiPrefix}/workCenters/map`,
        getDiagram: () => `${ApiPrefix}/workCenters/diagram`,
    },
    workFlowStatusTag: {
        create: () => `${ApiPrefix}/workFlowStatusTags`,
        byId: (id) => `${ApiPrefix}/workFlowStatusTags/${id}`,
        update: (id) => `${ApiPrefix}/workFlowStatusTags/${id}`,
        inWorkCenters: (id) => `${ApiPrefix}/workFlowStatusTags/inWorkCenters/${id}`,
        outWorkCenters: (id) => `${ApiPrefix}/workFlowStatusTags/outWorkCenters/${id}`,
    },
    workFlowStatusTags: {
        all: () => `${ApiPrefix}/workFlowStatusTags`,
        search: () => `${ApiPrefix}/workFlowStatusTags/search`,
        excelExport: () => `${ApiPrefix}/workFlowStatusTags/excelExport`,
    },
    employeeAccountStatuses: {
        search: () => `${ApiPrefix}/employeeAccountStatuses/search`,
        all: () => `${ApiPrefix}/employeeAccountStatuses`,
    },
    group: {
        all: () => `${ApiPrefix}/groups`,
        search: () => `${ApiPrefix}/groups/search`,
        byId: (groupId) => `${ApiPrefix}/group/${groupId}`,
        create: () => `${ApiPrefix}/group`,
        update: (groupId) => `${ApiPrefix}/group/${groupId}`,
        delete: (groupId) => `${ApiPrefix}/group/${groupId}`,
    },
    userGroup: {
        all: () => `${ApiPrefix}/userGroups`,
        search: () => `${ApiPrefix}/userGroups/search`,
        byId: (userGroupId) => `${ApiPrefix}/userGroup/${userGroupId}`,
        create: () => `${ApiPrefix}/userGroup`,
        update: (userGroupId) => `${ApiPrefix}/userGroup/${userGroupId}`,
        delete: (userGroupId) => `${ApiPrefix}/userGroup/${userGroupId}`,
    },
    permission: {
        all: () => `${ApiPrefix}/permissions`,
        search: () => `${ApiPrefix}/permissions/search`,
        byId: (permissionId) => `${ApiPrefix}/permission/${permissionId}`,
        create: () => `${ApiPrefix}/permission`,
        update: (permissionId) => `${ApiPrefix}/permission/${permissionId}`,
        delete: (permissionId) => `${ApiPrefix}/permission/${permissionId}`,
    },
    role: {
        all: () => `${ApiPrefix}/roles`,
        search: () => `${ApiPrefix}/roles/search`,
        byId: (roleId) => `${ApiPrefix}/role/${roleId}`,
        create: () => `${ApiPrefix}/role`,
        update: (roleId) => `${ApiPrefix}/role/${roleId}`,
        delete: (roleId) => `${ApiPrefix}/role/${roleId}`,
    },
    SalesOrderTracking: {
        search: () => `${ApiPrefix}/salesordertracking/search`,
        searchByWorkFlowStatusTagId: (id) => `${ApiPrefix}/salesordertracking/searchByWorkFlowStatusTagId/${id}`,
        createFromItem: () => `${ApiPrefix}/salesordertracking/createfromitem`,
        createFromFiles: () => `${ApiPrefix}/salesordertracking/createfromfiles`,
        engineeringChangeOrder: () => `${ApiPrefix}/salesordertracking/changeorder/fromfiles`,
        byId: (id) => `${ApiPrefix}/salesordertracking/${id}`,
        baseById: (id) => `${ApiPrefix}/salesordertracking/base/${id}`,
        delete: (id) => `${ApiPrefix}/salesordertracking/delete/${id}`,
        excelExport: () => `${ApiPrefix}/salesordertracking/exportexcel`,
        upload: () => `${ApiPrefix}/salesordertracking/upload`,
        issueTrackingSearch: () => `${ApiPrefix}/salesordertracking/issuetracking/search`,
        issueTrackingExport: () => `${ApiPrefix}/salesordertracking/issuetracking/export`,
        issueTrackingGet: (id) => `${ApiPrefix}/salesordertracking/issuetracking/${id}`,
        issueTrackingPut: () => `${ApiPrefix}/salesordertracking/issuetracking`,
        liftOptionPut: () => `${ApiPrefix}/salesordertracking/liftoption`,
        mountingInstructionPut: () => `${ApiPrefix}/salesordertracking/mountinginstruction`,
        drawings: (id) => `${ApiPrefix}/salesordertracking/drawing/${id}`,
        document: (id) => `${ApiPrefix}/salesordertracking/document/${id}`,
        media: (id) => `${ApiPrefix}/salesordertracking/media/${id}`,
        addtags: () => `${ApiPrefix}/salesordertracking/addtags/`,
        removetags: () => `${ApiPrefix}/salesordertracking/removetags/`,
        components: {
            workOrders: (id) => `${ApiPrefix}/salesordertracking/workorders/${id}`,
            workflowtags: (id) => `${ApiPrefix}/salesordertracking/workflowtags/${id}`,
            baseWorkflowTags: (id) => `${ApiPrefix}/salesordertracking/base/tags/${id}`,
            liftOptions: (id) => `${ApiPrefix}/salesordertracking/liftoptions/${id}`,
            mountingInstructions: (id) => `${ApiPrefix}/salesordertracking/mountinginstructions/${id}`,
            chassisInfo: (id) => `${ApiPrefix}/salesordertracking/chassisinfo/${id}`,
            drawings: (id) => `${ApiPrefix}/salesordertracking/drawings/${id}`,
            mediaitems: (id) => `${ApiPrefix}/salesordertracking/mediaitems/${id}`,
            sourceDocuments: (id) => `${ApiPrefix}/salesordertracking/sourcedocuments/${id}`,
            searchSurveys: () => `${ApiPrefix}/salesordertracking/surveys/seach`,
            quote: (id) => `${ApiPrefix}/salesordertracking/quote/${id}`,
            quoteLines: (id) => `${ApiPrefix}/salesordertracking/quotelines/${id}`
        }
    },
    shipping: {
        base: () => `${ApiPrefix}/shipping`,
        search: () => `${ApiPrefix}/shipping/search`,
        getSalesOrders: () => `${ApiPrefix}/shipping/getsalesorders`,
        byId: (id) => `${ApiPrefix}/shipping/${id}`,
        excelExport: () => `${ApiPrefix}/shipping/excelExport`,
        shippingsalesorders: (id) => `${ApiPrefix}/shipping/shippingsalesorders/${id}`
    },
    USStates: () => `${ApiPrefix}/usstates`,
    typeAheads: {
        employeeGroups: () => `${ApiPrefix}/typeahead/getEmployeeGroups`,
        employees: () => `${ApiPrefix}/typeahead/getEmployees`,
        permissions: () => `${ApiPrefix}/typeahead/getPermissions`,
        roles: () => `${ApiPrefix}/typeahead/getRoles`,
        USStates: () => `${ApiPrefix}/typeahead/getUSStates`,
        trackableSalesOrders: () => `${ApiPrefix}/typeahead/getTrackableSalesOrders`,
        apteanCustomers: () => `${ApiPrefix}/typeahead/getApteanCustomers`,
        apteanProductFamilies: () => `${ApiPrefix}/typeahead/getApteanProductFamilies`,
        apteanProductCategories: () => `${ApiPrefix}/typeahead/getApteanProductCategories`,
        unitsOfMeasure: () => `${ApiPrefix}/typeahead/getUnitsOfMeasurement`,
        unitsOfMeasureDataTypes: () => `${ApiPrefix}/typeahead/getUnitOfMeasureDataTypes`,
        questionExpectedValRelationships: () => `${ApiPrefix}/typeahead/getQuestionExpectedValueRelationships`,
        questionExpectedValueTypes: () => `${ApiPrefix}/typeahead/getQuestionExpectedValueTypes`,
        surveyStatuses: () => `${ApiPrefix}/typeahead/getSurveyStatuses`,
        surveyCompletionStatuses: () => `${ApiPrefix}/typeahead/getSurveyCompletionStatuses`,
        questionOptions: () => `${ApiPrefix}/typeahead/getQuestionOptions`,
        questionInputTypes: () => `${ApiPrefix}/typeahead/getQuestionInputTypes`,
        questionOptionGroups: () => `${ApiPrefix}/typeahead/getQuestionOptionGroups`,
        questions: () => `${ApiPrefix}/typeahead/getQuestions`,
        surveys: () => `${ApiPrefix}/typeahead/getSurveys`,
        quoteDispositionStatuses: () => `${ApiPrefix}/typeahead/getQuoteDispositionStatuses`,
        quoteChangeOrderStatuses: () => `${ApiPrefix}/typeahead/getQuoteChangeOrderStatuses`,
        getLiftModels: () => `${ApiPrefix}/typeahead/getLiftModels`,
        getLiftTypes: () => `${ApiPrefix}/typeahead/getLiftTypes`,
        getCriteriaTypes: () => `${ApiPrefix}/typeahead/getCriteriaTypes`,
        getMountingInstructions: () => `${ApiPrefix}/typeahead/getMountingInstructions`,
        getSalesOrderTrackingDerived: () => `${ApiPrefix}/typeahead/getSalesOrderTrackingProperties`,
        getLiftOptions: () => `${ApiPrefix}/typeahead/getLiftOptions`,
        getWorkCenters: () => `${ApiPrefix}/typeahead/getWorkCenters`,
        getTailWorkCenters: () => `${ApiPrefix}/typeahead/getTailWorkCenters`,
        getWorkCenterRestrictionRelationships: () => `${ApiPrefix}/typeahead/getWorkCenterRestrictionRelationships`,
        getWorkCenterRestrictionTypes: () => `${ApiPrefix}/typeahead/getWorkCenterRestrictionTypes`
    },
    common: {
        getLogo: () => `${ApiPrefix}/sitesettings/getSiteLogo`,
        mutableSettings: () => `${ApiPrefix}/mutableSettings`,
    },
    forms: {
        search: () => `${ApiPrefix}/forms/search`,
        create: () => `${ApiPrefix}/forms/create`,
        byid: (id) => `${ApiPrefix}/forms/${id}`,
        buildbyid: (id) => `${ApiPrefix}/forms/build/${id}`,
        excelExport: () => `${ApiPrefix}/forms/exportexcel`,
        //Sections
        section: () => `${ApiPrefix}/forms/section`,
        sectionquestion: () => `${ApiPrefix}/forms/sectionquestion`,
        exportformats: () => `${ApiPrefix}/forms/exportformats`,
        exportformatfields: (id) => `${ApiPrefix}/forms/exportformatfields/${id}`
    },
    questions: {
        search: () => `${ApiPrefix}/questions/search`,
        create: () => `${ApiPrefix}/questions/create`,
        byid: (id) => `${ApiPrefix}/questions/${id}`,
        excelExport: () => `${ApiPrefix}/questions/exportexcel`,
    },
    questionoptions: {
        search: () => `${ApiPrefix}/questions/options/search`,
        create: () => `${ApiPrefix}/questions/options/create`,
        byid: (id) => `${ApiPrefix}/questions/options/${id}`,
        excelExport: () => `${ApiPrefix}/questions/options/exportexcel`,
        all: () => `${ApiPrefix}/questions/options/all`
    },
    questionoptiongroups: {
        search: () => `${ApiPrefix}/questions/optiongroups/search`,
        create: () => `${ApiPrefix}/questions/optiongroups/create`,
        byid: (id) => `${ApiPrefix}/questions/optiongroups/${id}`,
        excelExport: () => `${ApiPrefix}/questions/optiongroups/exportexcel`,
    },
    unitsofmeasurement: {
        search: () => `${ApiPrefix}/questions/unitsofmeasurement/search`,
        create: () => `${ApiPrefix}/questions/unitsofmeasurement/create`,
        byid: (id) => `${ApiPrefix}/questions/unitsofmeasurement/${id}`,
        excelExport: () => `${ApiPrefix}/questions/unitsofmeasurement/exportexcel`,
    }
};

export const agGridConstants = {
    columnFilterTypes: {
        number: 'agNumberColumnFilter', //A Number Filter for number comparisons.
        text: 'agTextColumnFilter', //A Text Filter for string comparisons.
        date: 'agDateColumnFilter', //A Date Filter for date comparisons.
        set: 'agSetColumnFilter', //A Set Filter, influenced by how filters work in Microsoft Excel.This is an ag- Grid - Enterprise feature.
    },
};

export const ApplicationPermissions = {
    administrator: 'administrator',

    employee_create: 'employee.create',
    employee_edit: 'employee.edit',

    employee_delete: 'employee.delete',
    employee_view: 'employee.view',
    employee_home_address_edit: 'employee_home_address.edit',
    employee_username_edit: 'employee_username.edit',
    employee_role_edit: 'employee_role.edit',
    employee_name_edit: 'employee_name.edit',
    employee_number_edit: 'employee_number.edit',
    employee_phone_edit: 'employee_phone.edit',
    employee_notifications_edit: 'employee_notifications.edit',
    employee_accountstatus_edit: 'employee_accountstatus.edit',
    employee_password_edit: 'employee_password.edit',

    work_center_view: "work_center.view",
    work_center_create: "work_center.create",
    work_center_edit: "work_center.edit",
    work_center_delete: "work_center.delete",

    sales_order_tracking_create:  "sales_order_tracking.create",
    sales_order_tracking_view: "sales_order_tracking.view",
    sales_order_tracking_edit: "sales_order_tracking.edit",
    sales_order_tracking_delete:  "sales_order_tracking.delete",

    work_flow_status_tag_view: "work_flow_status_tag.view",
    work_flow_status_tag_create: "work_flow_status_tag.create",
    work_flow_status_tag_edit: "work_flow_status_tag.edit",
    work_flow_status_tag_delete: "work_flow_status_tag.delete",

    quote_view: "quote.view",
    quote_create: "quote.create",
    quote_edit: "quote.edit",
    quote_delete: "quote.delete",

    role_create: "role.create"
};

export const Weekdays = [
    { id: 0, name: 'Sunday', abbreviation: 'Sun', abbreviationShort: 'S' },
    { id: 1, name: 'Monday', abbreviation: 'Mon', abbreviationShort: 'M' },
    { id: 2, name: 'Tuesday', abbreviation: 'Tue', abbreviationShort: 'T' },
    { id: 3, name: 'Wednesday', abbreviation: 'Wed', abbreviationShort: 'W' },
    { id: 4, name: 'Thursday', abbreviation: 'Thu', abbreviationShort: 'R' },
    { id: 5, name: 'Friday', abbreviation: 'Fri', abbreviationShort: 'F' },
    { id: 6, name: 'Saturday', abbreviation: 'Sat', abbreviationShort: 'S' }
];

export const SupportedFileExtensions = {
    image: ['.gif', '.jpg', '.jpeg', '.png', '.heic'],
    document: ['.xlsx', '.xls', '.pdf', '.txt', '.doc', '.docx'],
};

export const SiteRoles = {
    Administrator: '6f176015-2b18-4d7a-896a-aa97c498ea66',
    RegionalSupport: '4488e0d6-0e54-4079-8b15-39195c23188e',
};

export const ServiceResponseResult = {
    Ok: 0,
    Error: 1,
    OkWithErrors: 2,
};

export const MomentTimeFormat = {
    TwentyFourHourNoLeading: 'H:mm',
    TwentyFourHourWithLeading: 'HH:mm',
    TwelveHourWithLeading: 'hh:mm',
    TwelveHourNoLeading: 'h:mm',
    AnteTwentyFourHour: 'H:mm A',
    AnteTwelveHour: 'h:mm A',
};

export const MomentDateFormat = {
    DateOnlyUTCHyphenated: 'YYYY-MM-DD',
    DateOnlyUTCSlashes: 'YYYY/MM/DD',
    MonthDayYearHyphensNoLeading: 'M-DD-YYYY',
    MonthDayYearHyphensLeading: 'MM-DD-YYYY',
    MonthDayYearSlashesNoLeading: 'M/DD/YYYY',
    MonthDayYearSlashesLeading: 'MM/DD/YYYY',
    DateTimeShortAnte: 'M/D/YY, h:mm A',
};

export const Months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const MonthsShort = [
    'Jan',
    'Feb',
    'Ma',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const ApplicationTheme = {
    primaryColor: '#343a40',
    secondaryColor: '#cccccc',
    warningColor: '#f18b0e',
    dangerColor: '#ed1d23',
    infoColor: '#1e5fb0',
    defaultHighlightColor: '#ed1b24',
    defaultHighlightAccentColor: '#ffa7a7',
    successColor: '#007bff'
}