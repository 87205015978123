import React from 'react';
import { Status } from './Status';
import './StatusForm.scss';
import cls from 'classnames';
import CommonContext, { ApiRoutes } from '../../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FormLabel, FormValidated, SmallButton } from '../forms/FormElements';
import { Row, Col, FormGroup, Input } from 'reactstrap';
import { util } from '../../Util';
import ValidationMessageDisplay from '../forms/ValidationMessageDisplay';

export class StatusForm extends React.Component {

    static contextType = CommonContext;

    // #region [ Constructor and Overrides ]

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            formValidated: false,
            loading: true,
            errors: {},
            validationMessage: '',            
            status: props.status ?? new Status(),
            isValidated: false,
            statusName: ''
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onCatchFetchError = this.onCatchFetchError.bind(this);
    }

    componentDidMount() {
        this.populateState();
    }

    static getDerivedStateFromProps(props, state) {
        if (!!props.status && props.status !== state.status)
            return { status: props.status, errors: {} };
        return null;
    }

    async populateState() {      
        this.setState(state => {
            return {
                loading: false
            }
        });
    }

    // #endregion

    onChange = e => {
        let { status } = this.state;
        status[e.target.name] = e.target.value;
        this.setState({ status: status });
    }

    onCatchFetchError(err) {
        if (!!err && !!err.validationMessages && (Object.keys(err.validationMessages ?? {}) ?? []).length) {
            this.setState((state) => {
                return {
                    errors: err.validationMessages,
                    validationMessage: err.validationMessages.Description.join("\r\n")
                };
            });
        }
        else if (!!err && !!err.message) {
            this.setState((state) => {
                return {
                    errors: [err.message],
                    validationMessage: [err.message]
                };
            });
            
        }
        else {
            alert('There was an error when saving the status.')
        }

        return;
    }

    onClearErrorNotificationClicked = e => {
        e.stopPropagation();
        this.setState((state) => { return { errors: {} }; });
    }

    onSubmit = async e => {
        const { status } = this.state;

        //Clear any fluent api errors
        this.setState((state) => { return { errors: {} }; });

        //Is this POST or PUT?
        let url = status.id ? ApiRoutes[this.props.route].update(status.id) : ApiRoutes[[this.props.route]].create();
        let fetch_addr = status.id ? util.fetch.put : util.fetch.post

        let response = await fetch_addr(url, status).catch(this.onCatchFetchError);

        if (response) {
            this.resetForm();
            this.props.onSaveCallback(response);
        }
    }

    resetForm() {
        this.setState({ formValidated: false });
    }

    onClose(response) {
        this.resetForm();
        this.props.onClose(response);
    }


    onDelete = async e => {
        const { status } = this.state;
        let response = await util.fetch.delete(ApiRoutes[[this.props.route]].delete(status.id)).catch(this.onCatchFetchError);
        if (response)
            this.onClose(response);
    }

    render() {
        if (this.state.loading) {
            return (<p><em>Loading...</em></p>)
        } else {
            const { status, errors, validationMessage } = this.state;
            let classNames = cls('slide-form col-xl-3 col-md-6 col-sm-12', { 'show': this.props.show })
            return (
                <div className={classNames}>
                    <FormValidated
                        ref={this.formRef}
                        setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                        isValidated={this.state.formValidated}
                        className="m-0 pb-2 w-100"
                        id="statusForm"
                        name="statusForm"
                        onSubmit={this.onSubmit}
                    >
                        <header className="border-bottom d-flex flex-column position-relative mb-3">
                            <span id="statusFormClose" className="cursor-pointer" onClick={this.onClose}>
                                <FontAwesomeIcon size="lg" icon={faTimes} />
                            </span>
                            <h5>
                                <div className="d-flex flex-row align-items-center mb-2">
                                    <FontAwesomeIcon className="mr-2 text-muted" size="lg" icon={faSave} />
                                    <span>{status.id ? `Edit ${this.props.statusName}` : `New ${this.props.statusName}`}</span>
                                </div>
                            </h5>
                            <div className="d-flex flex-row align-items-center text-muted">
                                <SmallButton type="submit" name="" onClick={this.onSave}>
                                    <FontAwesomeIcon className="mr-2" icon={faSave} />Save
                                </SmallButton>
                                {!!this.state.status.id ?
                                    <SmallButton onClick={this.onDelete}>
                                        <FontAwesomeIcon className="mr-2" icon={faTrashAlt} />Remove
                                    </SmallButton> : ''
                                }
                            </div>
                        </header>
                        <ValidationMessageDisplay onClear={this.onClearErrorNotificationClicked} errors={errors} message={validationMessage} />
                        <Row>
                            <Col>
                                <FormGroup>
                                    <FormLabel htmlFor="description"
                                        text="Description"
                                        required={true} />
                                    <Input id="description"
                                        name="description"
                                        value={status.description ?? ''}
                                        onChange={this.onChange}
                                        placeholder="Enter a description"
                                        type="text"
                                        required
                                    />
                                    <small className="invalid-feedback text-danger">Description is required.</small>
                                </FormGroup>
                            </Col>
                        </Row>
                    </FormValidated>
                </div>
            );
        }
    }
}