import React, { Component } from "react"
import { FlexColumnCenter, PageWrap } from "../common/forms/FormElements"


export class NotAuthorizedPage extends Component {
    render() {
        return (
            <PageWrap>
                <FlexColumnCenter>
                    <span className="display-1 d-block text-danger text-center">401</span>
                    <span className="h4 text-danger text-center">You are not authorized to access this resource.</span>
                    <a href="/" className="btn btn-link">Back to Home</a>
                </FlexColumnCenter>
            </PageWrap>
        );
    }
}