import React, { Fragment } from 'react';
import { AppPageForm, FlexCenterRow, FormDivider, FormGroupColumn, GroupedRow, onFieldChange, onReactSelectChanged, SubHeading, ToastMessage } from '../common/forms/FormElements';
import { BaseFormViewModel } from '../common/ViewModel';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { faCheckCircle, faSave, faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { FormLabel } from '../common/forms/FormElements';
import { UserGroup } from './UserGroup';
import { Button, Input, FormGroup } from 'reactstrap';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prompt, withRouter } from 'react-router-dom';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash-es';

class UserGroupForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        let stateBase = Object.assign(
            {   
                group: new UserGroup(),
                userGroups: []                
            },
            new BaseFormViewModel()
        );

        this.state = stateBase;
        this.onSelectChanged = this.onSelectChanged.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);        
    }

    componentDidMount = () => this.populateState();
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    async populateState() {
        const groupId = this.props.match.params.id;

        var [userGroups, group] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.employeeGroups()),
            !!groupId ? util.fetch.js(ApiRoutes.userGroup.byId(groupId)) : new UserGroup()
        ]);

        let originalData = util.object.clone(group);

        this.setState(state => {
            return {
                originalData: originalData,
                group: group,
                loading: false,
                userGroups: userGroups,
                saving: false
            }
        });
    }

    onChange = onFieldChange;
    onSelectChanged = onReactSelectChanged;
    handleSaveError = (err) => handleFormSaveError(this, err);
    onClearErrors = () => this.setState((state) => { return { errors: {} }; });
    setSaving = (b) => this.setState({ saving: b });

    onSubmit = async e => {
        //Clear any fluent api errors
        this.onClearErrors();
        this.setSaving(true);

        const { group } = this.state;

        var cloned = util.object.clone(group);

        this.props.location.pathname == AppNavPaths.UserGroupNew ? this.createGroup(cloned) : this.updateGroup(cloned);
    }

    updateGroup = async (group) => { 

        let response = await util.fetch.put(ApiRoutes.userGroup.byId(group.id), group)
            .catch(this.handleSaveError);

        if (response && parseInt(response.data.Id ?? 0) >= 0) {            
            toast.success(<ToastMessage icon={faCheckCircle}
                header={'Save Successful'}
                message={`[${response.data.groupName}] saved.`}
            />);
            util.navigation.localRedirect(this, `${AppNavPaths.UserGroups}`);
        } 

        this.setSaving(false);        
    }

    createGroup = async (group) => {

        let response = await util.fetch.post(ApiRoutes.userGroup.create(), group)
            .catch(this.handleSaveError);

        if (response && !isNaN(response.data)) {

            toast.success(<ToastMessage icon={faCheckCircle}
                header={'Save Successful'}
                message={`Saved.`}
            />);
            util.navigation.localRedirect(this, `${AppNavPaths.UserGroup}/${response.data}`);
        }

        this.setSaving(false);
    }

    resetForm = () => this.setState({ formValidated: false });

    onClose = (response) => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    }

    onDelete = async e => {
        const { group } = this.state;
        let response = await util.fetch.delete(ApiRoutes.userGroup.delete(group.id)).catch(this.onCatchFetchError);
        if (response)
            this.onClose(response);
    }

    render() {
        const {
            group,
            originalData,
            errors,
            formValidated,
            userGroups,
            saving
        } = this.state;
        let existing = !!group.id;        
        return (
            <Fragment>
                <Prompt
                    when={!saving && !isEqual(originalData, group)}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                <AppPageForm
                    formShown={this.context.formIsOpen}
                    formId={"groupForm"}
                    formHeadingIcon={faWarehouse}
                    formHeading={!group.id ? 'New User Group' : 'Edit User Group'}
                    formName={"groupForm"}
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                    isValidated={formValidated}
                    saving={saving}
                    errors={errors}
                    onClearErrors={this.onClearErrors}
                    loading={this.state.loading}
                >
                    <SubHeading>Details</SubHeading>
                    <GroupedRow>                        
                        <FormGroupColumn>
                            <FormGroup>
                                <FormLabel htmlFor="groupName"
                                    text="Name"
                                    required={true} />
                                <Input id="groupName"
                                    name="group.groupName"
                                    value={group.groupName ?? ''}
                                    onChange={this.onChange}
                                    placeholder="Enter Name (max 150 characters)"
                                    maxLength="150"
                                    pattern="[^()/><\][\\\x22,;|]+"
                                    type="text"
                                    required
                                />
                                <small className="invalid-feedback text-danger">Name is required and can only contain the following special characters: hyphens and periods.</small>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="type" text={"Groups"} required />
                                <ValidatedSelect
                                    id="types"
                                    name="group.userGroups"
                                    required
                                    isMulti
                                    options={userGroups}
                                    value={(userGroups ?? []).filter(x => (group.userGroups ?? []).includes(x.value)) ?? ''} 
                                    onChange={this.onSelectChanged}
                                    validationMessage="A group is required."
                                />
                            </FormGroup>
                        </FormGroupColumn>             
                    </GroupedRow>
                    <FormDivider /> 
                                       
                    <FlexCenterRow className="mb-3">
                        <Button
                            disabled={!!this.state.saving}
                            size="sm"
                            type="submit"
                            color="primary"
                            name="groupForm">
                            <FontAwesomeIcon
                                className="mr-2"
                                icon={faSave} />
                            {!!this.state.saving
                                ? 'Saving...'
                                : (!existing ? 'Save New User Group' : 'Save')
                            }
                        </Button>
                    </FlexCenterRow>

                </AppPageForm>               
            </Fragment>
        );

    }
}
export default withRouter(UserGroupForm);