import React, { Fragment } from 'react';
import { AppPageForm, FlexCenterRow, FormDivider, FormGroupColumn, GroupedRow, onFieldChange, onReactSelectChanged, SubHeading, ToastMessage } from '../common/forms/FormElements';
import { BaseFormViewModel } from '../common/ViewModel';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { faCheckCircle, faSave, faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { FormLabel } from '../common/forms/FormElements';
import { Permission } from './Permission';
import { Button, Input, FormGroup } from 'reactstrap';
import { util } from '../Util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prompt, withRouter } from 'react-router-dom';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash-es';

class PermissionForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        let stateBase = Object.assign(
            {   
                permission: new Permission()   
            },
            new BaseFormViewModel()
        );

        this.state = stateBase;
        this.onSelectChanged = this.onSelectChanged.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);        
    }

    componentDidMount = () => this.populateState();
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    async populateState() {
        const permissionId = this.props.match.params.id;

        var [permission] = await Promise.all([            
            !!permissionId ? util.fetch.js(ApiRoutes.permission.byId(permissionId)) : new Permission()
        ]);

        let originalData = util.object.clone(permission);

        this.setState(state => {
            return {
                originalData: originalData,                
                loading: false,
                permission: permission,
                saving: false
            }
        });
    }

    onChange = onFieldChange;
    onSelectChanged = onReactSelectChanged;
    handleSaveError = (err) => handleFormSaveError(this, err);
    onClearErrors = () => this.setState((state) => { return { errors: {} }; });
    setSaving = (b) => this.setState({ saving: b });

    onSubmit = async e => {
        //Clear any fluent api errors
        this.onClearErrors();
        this.setSaving(true);

        const { permission } = this.state;

        var cloned = util.object.clone(permission);

        this.props.location.pathname == AppNavPaths.PermissionNew ? this.createPermission(cloned) : this.updatePermission(cloned);
    }

    updatePermission = async (permission) => { 

        let response = await util.fetch.put(ApiRoutes.permission.byId(permission.id), permission)
            .catch(this.handleSaveError);

        if (response && parseInt(response.data.Id ?? 0) >= 0) {            
            toast.success(<ToastMessage icon={faCheckCircle}
                header={'Save Successful'}
                message={`[${response.data.claimName}] saved.`}
            />);
            util.navigation.localRedirect(this, `${AppNavPaths.Permissions}`);
        }

        this.setSaving(false);
    }

    createPermission = async (permission) => {

        let response = await util.fetch.post(ApiRoutes.permission.create(), permission)
            .catch(this.handleSaveError);

        if (response && !isNaN(response.data)) {

            toast.success(<ToastMessage icon={faCheckCircle}
                header={'Save Successful'}
                message={`Saved.`}
            />);
            util.navigation.localRedirect(this, `${AppNavPaths.Permission}/${response.data}`);
        }

        this.setSaving(false);
    }

    resetForm = () => this.setState({ formValidated: false });

    onClose = (response) => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    }

    onDelete = async e => {
        const { permission } = this.state;
        let response = await util.fetch.delete(ApiRoutes.permission.delete(permission.id)).catch(this.onCatchFetchError);
        if (response)
            this.onClose(response);
    }

    render() {
        const {
            permission,
            originalData,
            errors,
            formValidated,
            saving
        } = this.state;
        let existing = !!permission.id;        
        return (
            <Fragment>
                <Prompt
                    when={!saving && !isEqual(originalData, permission)}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                <AppPageForm
                    formShown={this.context.formIsOpen}
                    formId={"permissionForm"}
                    formHeadingIcon={faWarehouse}
                    formHeading={!permission.id ? 'New Permission' : 'Edit Permission'}
                    formName={"permissionForm"}
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                    isValidated={formValidated}
                    saving={saving}
                    errors={errors}
                    onClearErrors={this.onClearErrors}
                    loading={this.state.loading}
                >
                    <SubHeading>Details</SubHeading>
                    <GroupedRow>                        
                        <FormGroupColumn>
                            <FormGroup>
                                <FormLabel htmlFor="permissionName"
                                    text="Name"
                                    required={true} />
                                <Input id="permissionName"
                                    name="permission.claimName"
                                    value={permission.claimName ?? ''}
                                    onChange={this.onChange}
                                    placeholder="Enter Name (max 150 characters)"
                                    maxLength="150"
                                    pattern="[^()/><\][\\\x22,;|]+"
                                    type="text"
                                    required
                                />
                                <small className="invalid-feedback text-danger">Name is required and can only contain the following special characters: hyphens and periods.</small>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="permissionCategory"
                                    text="Category"
                                    required={true} />
                                <Input id="permissionCategory"
                                    name="permission.claimCategory"
                                    value={permission.claimCategory ?? ''}
                                    onChange={this.onChange}
                                    placeholder="Enter Category (max 150 characters)"
                                    maxLength="150"
                                    pattern="[^()/><\][\\\x22,;|]+"
                                    type="text"
                                    required
                                />
                                <small className="invalid-feedback text-danger">Category is required and can only contain the following special characters: hyphens and periods.</small>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="permissionAction"
                                    text="Action"
                                    required={true} />
                                <Input id="permissionAction"
                                    name="permission.claimAction"
                                    value={permission.claimAction ?? ''}
                                    onChange={this.onChange}
                                    placeholder="Enter Action (max 150 characters)"
                                    maxLength="150"
                                    pattern="[^()/><\][\\\x22,;|]+"
                                    type="text"
                                    required
                                />
                                <small className="invalid-feedback text-danger">Action is required and can only contain the following special characters: hyphens and periods.</small>
                            </FormGroup>
                        </FormGroupColumn>             
                    </GroupedRow>
                    <FormDivider /> 
                                       
                    <FlexCenterRow className="mb-3">
                        <Button
                            disabled={!!this.state.saving}
                            size="sm"
                            type="submit"
                            color="primary"
                            name="permissionForm">
                            <FontAwesomeIcon
                                className="mr-2"
                                icon={faSave} />
                            {!!this.state.saving
                                ? 'Saving...'
                                : (!existing ? 'Save New Permission' : 'Save')
                            }
                        </Button>
                    </FlexCenterRow>

                </AppPageForm>               
            </Fragment>
        );

    }
}
export default withRouter(PermissionForm);