import React from 'react'
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

export class ServerError extends Component {
    render() {
        return (
            <div className="site-page-wrap h-100 d-flex flex-row align-items-start">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <span className="display-1 d-block text-danger">500</span>
                            <div className="mb-4 lead text-danger">There was a server error when processing your request.  Please try your request again or contact support.</div>
                            <a href="/" className="btn btn-link">Back to Home</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(ServerError);